import { useState, useEffect } from 'react';

export const getCurrentOs = () => {
  const userAgent = window.navigator?.userAgent;

  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  const linuxPlatforms = ['Linux'];

  let OSName = 'Unknown OS';
  if (windowsPlatforms.some(platform => userAgent.includes(platform))) OSName = 'Windows';
  if (macosPlatforms.some(platform => userAgent.includes(platform))) OSName = 'MacOS';
  if (iosPlatforms.some(platform => userAgent.includes(platform))) OSName = 'IOS';
  if (linuxPlatforms.some(platform => userAgent.includes(platform))) OSName = 'Linux';

  return OSName;
};


export const useKeyLock = (key: string) => {
  const [toggled, setToggled] = useState(false);
  // getCurrentOs() is necessary to cover buttons listeners handling on various operating systems
  const isMacOs = getCurrentOs() === 'MacOS';

  useEffect(() => {
    const onKeyAction = isMacOs
      ? (event: KeyboardEvent) => setToggled(
        prevToggled => event.getModifierState?.(key) ?? prevToggled,
      )
      : (event: KeyboardEvent) => setToggled(
        prevToggled => (event.key === key ? !prevToggled : prevToggled),
      );

    if (isMacOs) document.addEventListener('keyup', onKeyAction);
    document.addEventListener('keydown', onKeyAction);
    return () => {
      if (isMacOs) document.removeEventListener('keyup', onKeyAction);
      document.removeEventListener('keydown', onKeyAction);
    };
  }, [key]);

  return toggled;
};
