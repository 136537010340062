import { createAction } from 'redux-actions';

export const passwordChangeInitialized = createAction(
  '@platform/command/me/changePassword/init',
);
export const passwordChangeSucceeded = createAction(
  '@platform/command/me/changePassword/success',
);
export const passwordChangeFailed = createAction(
  '@platform/command/me/changePassword/failed',
);
export const userCreationInitialized = createAction(
  '@platform/command/users/create/init',
);
export const userCreationSucceeded = createAction(
  '@platform/command/users/create/success',
);
export const userCreationFailed = createAction(
  '@platform/command/users/create/failed',
);
export const userInvitationInitialized = createAction(
  '@platform/command/users/invite/init',
);
export const userInvitationSucceeded = createAction(
  '@platform/command/users/invite/success',
);
export const userInvitationFailed = createAction(
  '@platform/command/users/invite/failed',
);
export const userDetailsFetchInitialized = createAction(
  '@platform/query/users/get/init',
);
export const userDetailsFetchSucceeded = createAction(
  '@platform/query/users/get/success',
  (payload: any) => payload,
);
export const userDetailsFetchFailed = createAction(
  '@platform/query/users/get/failed',
);
export const userUpdateInitialized = createAction(
  '@platform/command/users/update/init',
);
export const userUpdateFailed = createAction(
  '@platform/command/users/update/failed',
);
export const userUpdateSucceeded = createAction(
  '@platform/command/users/update/success',
  (payload: any) => payload,
);
export const userDeleteInitialized = createAction(
  '@platform/command/users/delete/init',
);
export const userDeleteSucceeded = createAction(
  '@platform/command/users/delete/success',
);
export const userDeleteFailed = createAction(
  '@platform/command/users/delete/failed',
);
export const usersFetchInitialized = createAction(
  '@platform/query/users/list/init',
  (payload: any) => payload,
);
export const usersFetchSucceeded = createAction(
  '@platform/query/users/list/success',
  (payload: any) => payload,
);
export const usersFetchFailed = createAction(
  '@platform/query/users/list/failed',
);
export const languageChangeInitialized = createAction(
  '@platform/command/me/changeLanguage/init',
);
export const languageChangeSucceeded = createAction(
  '@platform/command/me/changeLanguage/success',
  (language: string) => ({ language }),
);
export const languageChangeFailed = createAction(
  '@platform/command/me/changeLanguage/failed',
);
export const checkUserSetupInitialized = createAction(
  '@platform/query/users/setup/checkPasswordCode/init',
);
export const checkUserSetupSucceeded = createAction(
  '@platform/query/users/setup/checkPasswordCode/success',
  (payload: string) => payload,
);
export const checkUserSetupFailed = createAction(
  '@platform/query/users/setup/checkPasswordCode/failed',
  (payload: string) => payload,
);
