import type { Store } from 'redux';
import {
  Service,
  inject,
} from '@piwikpro/platform';
import { HttpClient } from '@piwikpro/http-crate';
import { Confirmation } from '@piwikpro/confirmation-crate';
import { TranslateService } from '@piwikpro/translation-crate';
import { ProductAnalytics } from '@piwikpro/product-analytics/ProductAnalytics';
import { IUser } from './interfaces';
import {
  passwordChangeInitialized,
  passwordChangeSucceeded,
  passwordChangeFailed,
  userDetailsFetchInitialized,
  userDetailsFetchSucceeded,
  userDetailsFetchFailed,
  userUpdateInitialized,
  userUpdateSucceeded,
  userUpdateFailed,
  userDeleteInitialized,
  userDeleteSucceeded,
  userDeleteFailed,
  userCreationInitialized,
  userCreationSucceeded,
  userCreationFailed,
  userInvitationInitialized,
  userInvitationSucceeded,
  userInvitationFailed,
  usersFetchInitialized,
  usersFetchSucceeded,
  usersFetchFailed,
  languageChangeInitialized,
  languageChangeSucceeded,
  languageChangeFailed,
  checkUserSetupInitialized,
  checkUserSetupSucceeded,
  checkUserSetupFailed,
} from './reduxActions';

export interface ChangePasswordAttributes {
  currentPassword: string
  newPassword: string
}

interface UpdateUserAttributes {
  language?: string
  role?: string
}

interface CreateUserAttributes {
  email: string
  password: string
  passwordRepeated: string
}

interface FetchUsersParams {
  limit: number
  offset: number
  sortBy: string
  sortDirection: string
  search: string
}

interface InviteUserResponse {
  data: IUser
}

@Service()
export class Users {
  private readonly usersApiUrl: string;

  constructor(
    @inject('store') private readonly store: Store,
    @inject('TranslationCrate.translate') private readonly translate: TranslateService,
    @inject('HttpCrate.httpClient') private readonly httpClient: HttpClient,
    @inject('NotificationCrate.interceptors.NotifyOnFail') private readonly notifyOnFailInterceptor: any,
    @inject('NotificationCrate.interceptors.NotifyAboutUpdate') private readonly notifyAboutUpdateInterceptor: any,
    @inject('NotificationCrate.interceptors.NotifyAboutCreation') private readonly notifyAboutCreationInterceptor: any,
    @inject('NotificationCrate.interceptors.NotifyAboutDelete') private readonly notifyAboutDeleteInterceptor: any,
    @inject('ConfirmationCrate.confirmation') private readonly confirmation: Confirmation,
    @inject('productAnalytics') private readonly productAnalytics: ProductAnalytics,
    @inject('config') private readonly config: any,
  ) {
    this.usersApiUrl = this.config.get('USERS_URL');
  }

  // TODO: remove after IDP 2.0 release (the same feature is applied in AuthCrate)
  async changePassword(userId: string, attributes: ChangePasswordAttributes): Promise<any> {
    this.store.dispatch(passwordChangeInitialized());

    try {
      const response = await this.httpClient.request({
        endpoint: `${this.usersApiUrl}/me/change-password`,
        method: 'PATCH',
        body: {
          data: {
            id: userId,
            type: 'ppms/user',
            attributes,
          },
        },
      })
        .intercept(this.notifyAboutUpdateInterceptor)
        .jsonApi()
        .send();

      this.store.dispatch(passwordChangeSucceeded());

      return response;
    } catch (err) {
      this.store.dispatch(passwordChangeFailed());

      throw err;
    }
  }

  async changeLanguageAndRegion(newLanguage: string): Promise<any> {
    this.store.dispatch(languageChangeInitialized());
    await this.translate.changeLanguage(newLanguage);

    try {
      await this.httpClient.request({
        endpoint: `${this.config.get('USERS_URL')}/me`,
        method: 'PATCH',
        body: {
          data: {
            id: this.store.getState().session.userId,
            type: 'ppms/user',
            attributes: {
              language: newLanguage,
            },
          },
        },
      }).intercept(this.notifyAboutUpdateInterceptor).jsonApi().send();
      this.store.dispatch(languageChangeSucceeded(newLanguage));
    } catch (error) {
      this.store.dispatch(languageChangeFailed());
      await this.translate.changeLanguage(this.store.getState().session.language);

      throw error;
    }
  }

  async getUserDetails(id: string): Promise<any> {
    this.store.dispatch(userDetailsFetchInitialized());

    try {
      const response: any = await this.httpClient.request({
        endpoint: `${this.usersApiUrl}/${id}`,
        method: 'GET',
      })
        .intercept(this.notifyOnFailInterceptor)
        .jsonApi()
        .send();

      this.store.dispatch(userDetailsFetchSucceeded(response.data));

      return response;
    } catch (err) {
      this.store.dispatch(userDetailsFetchFailed());

      throw err;
    }
  }

  async updateUser(
    id: string,
    attributes: UpdateUserAttributes,
  ) {
    this.store.dispatch(userUpdateInitialized());

    try {
      await this.httpClient.request({
        endpoint: `${this.usersApiUrl}/${id}`,
        method: 'PATCH',
        body: {
          data: {
            id,
            type: 'ppms/user',
            attributes,
          },
        },
      })
        .intercept(this.notifyAboutUpdateInterceptor)
        .jsonApi()
        .send();

      this.store.dispatch(userUpdateSucceeded({
        id,
        attributes,
      }));

      return {
        id,
        attributes,
      };
    } catch (err) {
      this.store.dispatch(userUpdateFailed());

      throw err;
    }
  }

  deleteUser(id: string, email: string = ''): Promise<void> {
    return this.confirmation.confirm({
      messages: [
        'administration:users.deleteConfirm.message-line-1',
        'administration:users.deleteConfirm.message-line-2',
      ],
      title: 'administration:users.deleteConfirm.title-with-name',
      name: email,
    })
      .then(() => {
        this.store.dispatch(userDeleteInitialized());

        return this.httpClient.request({
          endpoint: `${this.usersApiUrl}/${id}`,
          method: 'DELETE',
        })
          .intercept(this.notifyAboutDeleteInterceptor)
          .jsonApi()
          .send();
      })
      .then(() => {
        this.store.dispatch(userDeleteSucceeded());
      })
      .catch((err) => {
        this.store.dispatch(userDeleteFailed());

        throw err;
      });
  }

  async createUser(attributes: CreateUserAttributes) {
    const defaultAttributes = {
      role: 'USER',
      language: 'en-US',
    };
    this.store.dispatch(userCreationInitialized());

    try {
      const response = await this.httpClient.request({
        endpoint: this.usersApiUrl,
        method: 'POST',
        body: {
          data: {
            attributes: {
              ...defaultAttributes,
              ...attributes,
            },
            type: 'ppms/user',
          },
        },
        delay: this.config.get('REQUEST_DELAY'),
      })
        .intercept(this.notifyAboutCreationInterceptor)
        .jsonApi()
        .send();

      this.store.dispatch(userCreationSucceeded(response));

      return response;
    } catch (err) {
      this.store.dispatch(userCreationFailed());

      throw err;
    }
  }

  async inviteUser(attributes: Partial<CreateUserAttributes>) {
    this.store.dispatch(userInvitationInitialized());

    try {
      const response: InviteUserResponse = await this.httpClient.request({
        endpoint: `${this.usersApiUrl}/invite`,
        method: 'POST',
        body: {
          data: {
            attributes: {
              ...attributes,
            },
            type: 'ppms/user',
          },
        },
        delay: this.config.get('REQUEST_DELAY'),
      })
        .intercept(this.notifyAboutCreationInterceptor)
        .jsonApi()
        .send();

      this.store.dispatch(userInvitationSucceeded(response)); // used for product analytics
      return response;
    } catch (err) {
      this.store.dispatch(userInvitationFailed());

      throw err;
    }
  }

  async fetchUsers(params: FetchUsersParams) {
    if (!params.offset) {
      this.store.dispatch(usersFetchInitialized({ resetList: true }));
    } else {
      this.store.dispatch(usersFetchInitialized({ resetList: false }));
    }

    const queryParams = {
      ...params,
      sort: params.sortDirection + params.sortBy,
    };

    try {
      const response = await this.httpClient.request({
        endpoint: this.usersApiUrl,
        queryParams,
        method: 'GET',
      })
        .intercept(this.notifyOnFailInterceptor)
        .jsonApi()
        .send();
      this.store.dispatch(usersFetchSucceeded(response));

      return response;
    } catch (err) {
      this.store.dispatch(usersFetchFailed());

      throw err;
    }
  }

  async setupUser(
    { password, setPasswordCode, userType }:
    { password: string, setPasswordCode?: string, userType: 'invited' | 'first' },
  ): Promise<void | Error> {
    try {
      await this.httpClient.request({
        endpoint: `${this.usersApiUrl}/setup/${setPasswordCode}`,
        method: 'POST',
        body: {
          password,
        },
        retryNumber: 3,
        retryInterval: 500,
      })
        .intercept(this.notifyOnFailInterceptor)
        .json()
        .send();
      if (userType === 'first') {
        this.productAnalytics.push({ type: '@platform/command/users/first/setPassword/success' });
      }
      if (userType === 'invited') {
        this.productAnalytics.push({ type: '@platform/command/users/invited/setPassword/success' });
      }
      return;
    } catch (err) {
      if (userType === 'first') {
        this.productAnalytics.push({ type: '@platform/command/users/first/setPassword/failed' });
      }
      if (userType === 'invited') {
        this.productAnalytics.push({ type: '@platform/command/users/invited/setPassword/failed' });
      }
      throw err;
    }
  }

  async checkUserSetup(setPasswordCode: string): Promise<void | Error> {
    this.store.dispatch(checkUserSetupInitialized());

    try {
      await this.httpClient.request({
        endpoint: `${this.usersApiUrl}/setup/${setPasswordCode}`,
        method: 'GET',
        retryNumber: 3,
        retryInterval: 500,
      })
        .json()
        .send();

      this.store.dispatch(checkUserSetupSucceeded(setPasswordCode));

      return;
    } catch (err: any) {
      this.store.dispatch(checkUserSetupFailed(err));

      throw err;
    }
  }
}
