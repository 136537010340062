/* eslint-disable no-case-declarations */
import * as Yup from 'yup';

const domain = /^(\*\.)?([\w-]+\.)+[\w]{2,}$/;
export default (yup: typeof Yup, t: any) => ({
  name: yup.string()
    .test('wildcardUrl', 'Wildcards aren’t allowed for this domain.', (value: any) => !value?.includes('*'))
    .test('domain', 'Invalid domain.', (value: any) => domain.test(value))
    .required(),
  types: yup.array().of(yup.string()).strict(true),
  certificate: yup.object().shape({
    chain: yup.string().required(),
    key: yup.string().required(),
  }).required(),
});
