/* eslint-disable camelcase */
import { handleActions } from 'redux-actions';
import {
  checkUserSetupInitialized,
  checkUserSetupSucceeded,
  checkUserSetupFailed,
} from '@piwikpro/users-crate';

export interface ISetPasswordProcessState {
  isLoading: boolean
  failed: boolean
  failReason: string
  passwordCode: string
}

const initialState: ISetPasswordProcessState = {
  isLoading: true,
  failed: false,
  failReason: '',
  passwordCode: '',
};

export default handleActions<ISetPasswordProcessState, any>({
  [checkUserSetupInitialized.toString()]: () => ({
    ...initialState,
  }),
  [checkUserSetupSucceeded.toString()]: (
    state,
    { payload }: { payload: string },
  ) => ({
    ...state,
    isLoading: false,
    passwordCode: payload,
  }),
  [checkUserSetupFailed.toString()]: (state, { payload }: { payload: string }) => ({
    ...state,
    isLoading: false,
    failed: true,
    failReason: payload,
  }),
}, initialState);
