/* eslint-disable camelcase */
import { handleActions, createAction } from 'redux-actions';

export const checkResetPasswordCodeInitialized = createAction(
  '@astronauth/query/checkResetPasswordCode/get/init',
);
export const checkResetPasswordCodeSucceeded = createAction(
  '@astronauth/query/users/setup/checkResetPasswordCode/get/success',
  (payload: string) => payload,
);
export const checkResetPasswordCodeFailed = createAction(
  '@astronauth/query/users/setup/checkResetPasswordCode/get/failed',
  (payload: string) => payload,
);

export interface IResetPasswordProcessState {
  isLoading: boolean
  failed: boolean
  failReason: string
  passwordCode: string
}

const initialState: IResetPasswordProcessState = {
  isLoading: true,
  failed: false,
  failReason: '',
  passwordCode: '',
};

export default handleActions<IResetPasswordProcessState, any>({
  [checkResetPasswordCodeInitialized.toString()]: () => ({
    ...initialState,
  }),
  [checkResetPasswordCodeSucceeded.toString()]: (
    state,
    { payload }: { payload: string },
  ) => ({
    ...state,
    isLoading: false,
    passwordCode: payload,
  }),
  [checkResetPasswordCodeFailed.toString()]: (state, { payload }: { payload: string }) => ({
    ...state,
    isLoading: false,
    failed: true,
    failReason: payload,
  }),
}, initialState);
