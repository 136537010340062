import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FormikProps } from 'formik';
import { TFunction } from 'react-i18next';
import classNameBind from 'classnames/bind';
import { withInject } from '@piwikpro/platform';
import { FormValidator } from '@piwikpro/form-crate';
import {
  Stack,
  Header,
  Information,
  Text,
  Form,
  Link,
  Divider,
  Caption,
  TextFieldControl,
  withTranslation,
} from '@piwikpro/ui-components';
import { TOTP_BACKUP_CODE_LENGTH } from '../../../../constants';
import { AstronAuthService } from '../../../../services/AstronAuth';
import styles from './TotpBackupCodeInput.module.css';
import { IAstronAuthState } from '../../../../reducers';

const bindClasses = classNameBind.bind(styles);

const mapStateToProps = (state: IAstronAuthState) => ({
  failed: state.authProcess.failed,
  failReason: state.authProcess.failReason,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

interface InjectedProps {
  validation: FormValidator
  astronAuthApi: AstronAuthService
}

interface Props extends ReduxProps, InjectedProps {
  t: TFunction
}

interface IFormValues {
  code: number
}

export const TotpBackupCodeInput = ({
  validation,
  astronAuthApi,
  failed,
  failReason,
  t,
}: Props) => {
  const incorrectCredentials = failReason === 'UnauthorizedRequestError';
  const rateLimitExceeded = failReason === 'TooManyRequestsError';
  const otherFailReason = !(incorrectCredentials || rateLimitExceeded);

  const handleSubmit = async (
    values: IFormValues,
    api: FormikProps<IFormValues>,
  ) => {
    try {
      await astronAuthApi.enterTotp(
        { code: String(values.code) },
        'input-code',
      );

      api.setSubmitting(false);
    } catch {
      api.resetForm();
      api.setSubmitting(false);
    }
  };

  return (
    <Form
      id="totp-backup-code-input"
      initialValues={{
        code: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={validation.createValidationSchema(
        () => ({
          code: validation.getYup().string().length(TOTP_BACKUP_CODE_LENGTH, 'Use exactly 8 characters.'),
        }),
      )}
      render={({ isSubmitting, setFieldValue }: FormikProps<IFormValues>) => (
        <Stack vertical horizontalAlignment="middle">
          <Stack.Item>
            <Header type="small">
              {t('login.2faBackupCodesInput.header')}
            </Header>
          </Stack.Item>
          <Stack.Item fill>
            {/* TODO: verify subtitle component here to adjust left */}
            <div style={{ textAlign: 'center' }}>
              <Text>
                {t('login.2faBackupCodesInput.description')}
              </Text>
            </div>
          </Stack.Item>
          {failed && (
            <Stack.Item fill>
              <Information type="error">
                {incorrectCredentials && t('login.2fa.errors.incorrectCode')}
                {rateLimitExceeded && t('general.notifications.errors.rateLimit.text')}
                {otherFailReason && t('login.passwordInput.errors.other')}
              </Information>
            </Stack.Item>
          )}
          <Stack.Item fill>
            <div className={bindClasses('form-wrapper')}>
              <Stack
                spacing="narrow"
                fullWidth
              >
                <Stack.Item fill>
                  <TextFieldControl
                    name="code"
                    type="tel"
                    onChange={(e: {
                      target: { name: string, value: string }
                    }) => {
                      const numericValue = e.target.value.replace(/[^0-9]/g, '');
                      if (numericValue.length <= TOTP_BACKUP_CODE_LENGTH) setFieldValue('code', numericValue);
                    }}
                    autoFocus
                  />
                </Stack.Item>
                <Stack.Item>
                  <Form.Submit text={t('login.2faBackupCodesInput.confirmBtn')} />
                </Stack.Item>
              </Stack>
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className={bindClasses('link', { disabled: isSubmitting })}>
              <Link
                size="small"
                onClick={() => astronAuthApi.showNextStep('totp/input-code')}
              >
                {t('login.2faBackupCodesInput.standardCodeLink')}
              </Link>
            </div>
          </Stack.Item>
          <Stack.Item fill>
            <Divider noMargin />
          </Stack.Item>
          <Stack.Item fill>
            <div style={{ textAlign: 'center' }}>
              <Caption subdued>
                {t('login.2faBackupCodesInput.useAnotherAccountLink.part1')}
                <span className={bindClasses({ disabled: isSubmitting })}>
                  <Link
                    size="small"
                    onClick={async () => {
                      try {
                        await astronAuthApi.resetLoginProcess();
                        await astronAuthApi.getAuthStep();
                        // eslint-disable-next-line no-empty
                      } catch {}
                    }}
                  >
                    {t('login.2faBackupCodesInput.useAnotherAccountLink.part2')}
                  </Link>
                </span>
                {t('login.2faBackupCodesInput.useAnotherAccountLink.part3')}
              </Caption>
            </div>
          </Stack.Item>
        </Stack>
      )}
    />

  );
};

export default connector(withInject<InjectedProps>({
  validation: 'FormCrate.validation',
  astronAuthApi: 'AstronAuthCrate.astronAuth',
})(withTranslation(['astronauth'])(TotpBackupCodeInput)));
