export default {
  CDP:
  {
    HEADER: 'Use CDP to make your data actionable',
    BODY: 'Create complete customer profiles and segment your customer data into audiences. Activate them to provide a personalized experience and run effective campaigns across different channels.',
    IMAGE_URL: '/static/images/cdp.png',
    CTA_TEXT: 'See all benefits',
    CTA_URL: 'https://piwik.pro/customer-data-platform/',
    NEW_BADGE_VISIBILITY: true,
  },
  MODULES:
  {
    HEADER: 'Get accurate data, analyze and activate it to improve your product',
    BODY: 'Piwik PRO Analytics Suite includes Analytics, Tag Manager, Consent Manager, and Customer Data Platform. They all work together to help you gather, manage, and use data effectively while respecting privacy.',
    IMAGE_URL: '/static/images/modules.png',
    NEW_BADGE_VISIBILITY: false,
  },
  PRIVACY:
  {
    HEADER: 'Collect and act on data in a privacy-friendly way',
    BODY: 'Rely on Piwik PRO’s flexible data privacy capabilities and collect more data from more sources. Analyze and process your user data in line with privacy regulations and respect users’ rights.',
    IMAGE_URL: '/static/images/privacy.png',
    NEW_BADGE_VISIBILITY: false,
  },
};
