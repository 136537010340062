import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNameBind from 'classnames/bind';
import { TFunction } from 'react-i18next';
import QRCode from 'react-qr-code';
import { FormikProps } from 'formik';
import { withInject } from '@piwikpro/platform';
import {
  Stack,
  Header,
  Information,
  Text,
  Caption,
  Divider,
  Trans,
  Link,
  withTranslation,
} from '@piwikpro/ui-components';
import { FormValidator } from '@piwikpro/form-crate';
import VerificationCodeInput from '../../../../components/VerificationCodeInput';
import CopyIcon from '../../../../components/CopyIcon';
import { AstronAuthService } from '../../../../services/AstronAuth';
import { TOTP_INPUT_NUMBER_OF_FIELDS } from '../../../../constants';
import { getSecretFromTotpUri } from '../../../../utils';
import { IAstronAuthState, IAuthProcessData, IAuthProcessState } from '../../../../reducers';
import styles from './TotpConfigure.module.css';

const bindClasses = classNameBind.bind(styles);

const mapStateToProps = (state: IAstronAuthState) => ({
  stepData: state.authProcess.data,
  failed: state.authProcess.failed,
  failReason: state.authProcess.failReason,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

interface InjectedProps {
  astronAuthApi: AstronAuthService
  validation: FormValidator
}

interface Props extends ReduxProps, InjectedProps {
  t: TFunction
}

interface IFormValues {
  code: string
}

export const TotpConfigure = ({
  astronAuthApi,
  validation,
  failed,
  failReason,
  stepData,
  t,
}: Props) => {
  const secret = getSecretFromTotpUri(stepData.totp_uri!);
  const incorrectCredentials = failReason === 'UnauthorizedRequestError';
  const rateLimitExceeded = failReason === 'TooManyRequestsError';
  const otherFailReason = !(incorrectCredentials || rateLimitExceeded);

  const handleSubmit = async (
    values: IFormValues,
    api: FormikProps<IFormValues>,
  ) => {
    try {
      await astronAuthApi.enterTotp({ code: values.code }, 'configure');
      astronAuthApi.showNextStep('totp/backup-codes');

      api.setSubmitting(false);
    } catch {
      api.resetForm();
      api.setSubmitting(false);
    }
  };

  return (
    <Stack vertical horizontalAlignment="middle">
      <Stack.Item>
        <Header type="small">
          {t('login.2faConfigure.header')}
        </Header>
      </Stack.Item>
      <Stack.Item fill>
        {/* TODO: verify subtitle component here to adjust left */}
        <div style={{ textAlign: 'center' }}>
          <Text>
            <Trans
              t={t}
              i18nKey="login.2faConfigure.description.line1"
              components={[
                <b>text</b>,
              ]}
            />
          </Text>
        </div>
      </Stack.Item>
      <Stack.Item fill>
        <div style={{ textAlign: 'center' }}>
          <Text>
            <Trans
              t={t}
              i18nKey="login.2faConfigure.description.line2"
              components={[
                <b>text</b>,
              ]}
            />
          </Text>
        </div>
      </Stack.Item>
      <Stack.Item dataId="QRCode">
        <QRCode
          size={256}
          style={{ height: '189px', width: '189px' }}
          value={stepData.totp_uri!}
          viewBox="0 0 256 256"
        />
      </Stack.Item>
      <Stack.Item>
        <div style={{ textAlign: 'center' }}>
          <Text>
            <Trans
              t={t}
              i18nKey="login.2faConfigure.description.line3"
              components={[
                <b>text</b>,
              ]}
            />
          </Text>
        </div>
      </Stack.Item>
      <Stack.Item previousSpacing="narrow">
        {/* TODO: move all that copy something with icon to separate reusable component
          with children to avoid keeping styles in here just for that */}
        <div className={bindClasses('copy-content-wrapper')}>
          <Stack spacing="extraNarrow" verticalAlignment="center">
            <Stack.Item>
              <Caption subdued>{secret}</Caption>
            </Stack.Item>
            <Stack.Item>
              <div className={bindClasses('copy-icon')}>
                <CopyIcon value={secret} />
              </div>
            </Stack.Item>
          </Stack>
        </div>
      </Stack.Item>
      <Stack.Item fill>
        <div style={{ textAlign: 'center' }}>
          <Text>
            <Trans
              t={t}
              i18nKey="login.2faConfigure.description.line4"
              components={[
                <b>text</b>,
              ]}
            />
          </Text>
        </div>
      </Stack.Item>
      {failed && (
        <Stack.Item fill>
          <Information type="error">
            {incorrectCredentials && t('login.2fa.errors.incorrectCode')}
            {rateLimitExceeded && t('general.notifications.errors.rateLimit.text')}
            {otherFailReason && t('login.passwordInput.errors.other')}
          </Information>
        </Stack.Item>
      )}
      <Stack.Item>
        <VerificationCodeInput
          id="totp-configuration-form" // TODO: can be removed as it doesn't apply by default (Form problem?)
          name="code"
          initialValue=""
          validationSchema={validation.createValidationSchema(
            () => ({
              code: validation.getYup().string().min(TOTP_INPUT_NUMBER_OF_FIELDS).required(),
            }),
          )}
          handleResetLoginProcess={async () => {
            try {
              await astronAuthApi.resetLoginProcess();
              await astronAuthApi.getAuthStep();
              // eslint-disable-next-line no-empty
            } catch {}
          }}
          onSubmit={handleSubmit}
        />
      </Stack.Item>
      <Stack.Item fill>
        <Divider noMargin />
      </Stack.Item>
      <Stack.Item fill>
        <div style={{ textAlign: 'center' }}>
          <Caption subdued>
            <Trans
              t={t}
              i18nKey="login.2faConfigure.description.line5"
              components={[
                <Link size="small" href="https://help.piwik.pro/support/account/two-factor-authentication/">help center</Link>,
              ]}
            />
          </Caption>
        </div>
      </Stack.Item>
    </Stack>
  );
};

export default connector(withInject<InjectedProps>({
  validation: 'FormCrate.validation',
  astronAuthApi: 'AstronAuthCrate.astronAuth',
})(withTranslation(['astronauth'])(TotpConfigure)));
