import React, { FormEvent } from 'react';
import {
  Form,
  TextFieldControl,
  ModalView,
  Stack,
  Trans,
  Text,
  withTranslation,
  ButtonAppearance,
} from '@piwikpro/ui-components';
import { withInject } from '@piwikpro/platform';
import { FormValidator } from '@piwikpro/form-crate';
import { regExps } from '@piwikpro/form-crate/validators/regExps';

interface InjectedProps {
  i18n: any
  validation: FormValidator
}

export interface ConfirmWithCheckProps extends InjectedProps {
  messages: Array<string>
  name: string
  title: string
  applyBtnAppearance?: string
  applyBtnText?: string
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onCancel: () => void
  t: (key: string, variables?: any) => string
}

export class ConfirmWithCheck extends React.Component<ConfirmWithCheckProps> {
  constructor(props: ConfirmWithCheckProps) {
    super(props);

    this.validationSchema = this.validationSchema.bind(this);
  }

  validationSchema() {
    const {
      validation,
      i18n,
      name,
    } : ConfirmWithCheckProps = this.props;

    return validation.createValidationSchema(() => ({
      name: validation.getYup().string()
        .required()
      // this value may include special characters,
      // which we want to exclude from regexp during matching values
        .matches(new RegExp(`^${name.replace(regExps.specialCharactersToReplace, '\\$&')}$`, 'g'), i18n.t('administration:deleteConfirm.withCheck.notMatch')),
    }));
  }

  render() {
    const {
      messages,
      title,
      onCancel,
      t,
      applyBtnAppearance,
      applyBtnText,
      name,
      onSubmit,
    } : ConfirmWithCheckProps = this.props;

    return (
      <Form
        id="confirm-with-check-form"
        validationSchema={this.validationSchema}
        initialValues={{
          name: '',
        }}
        onSubmit={onSubmit}
      >
        <ModalView
          title={t(title, { name })}
          actions={(
            <Stack spacing="narrow" horizontalAlignment="right" verticalAlignment="center">
              <Form.Cancel onClick={onCancel} />
              <Form.Submit appearance={(applyBtnAppearance || 'default') as ButtonAppearance} text={applyBtnText ? t(applyBtnText) : t('administration:deleteConfirm.modal.submit-confirm')} />
            </Stack>
          )}
          onClose={onCancel}
        >
          <Stack vertical horizontalAlignment="fill" spacing="wide">
            <Stack.Item>
              <Stack vertical spacing="narrow">
                {messages?.map((message: string) => (
                  <Stack.Item key={message}>
                    <Text>
                      <Trans
                        i18nKey={t(message, { name })}
                        components={[
                          <b>text</b>,
                        ]}
                      />
                    </Text>
                  </Stack.Item>
                ))}
              </Stack>
            </Stack.Item>
            <Stack.Item previousSpacing="narrow">
              <TextFieldControl
                name="name"
                required
                autoFocus
              />
            </Stack.Item>
          </Stack>
        </ModalView>
      </Form>
    );
  }
}

export default withInject<InjectedProps>({
  i18n: 'TranslationCrate.i18n',
  validation: 'FormCrate.validation',
})(withTranslation(['administration', 'platform'])(ConfirmWithCheck));
