/* eslint-disable max-len */
import React from 'react';
import classNameBind from 'classnames/bind';
import { TFunction } from 'react-i18next';
import { History } from 'history';
import {
  Button,
  Text,
  Card,
  Stack,
  Link,
  Image,
  Header,
  Trans,
  withTranslation,
} from '@piwikpro/ui-components';
import { httpErrors } from '@piwikpro/http-crate';
import { withInject } from '@piwikpro/platform';
import { AstronAuthService } from '../../services/AstronAuth';
import { RESET_PASSWORD_URL, LOGIN_URL } from '../../constants';
import { useQuery } from '../../hooks/useQuery';
import internalServerError from './images/internalServerError.png';
import expired from './images/expired.png';
import noLongerValid from './images/noLongerValid.png';
import alreadyUsed from './images/alreadyUsed.png';
import accountDeleted from './images/accountDeleted.png';
import maintenanceMode from './images/maintenanceMode.png';
import styles from './Errors.module.css';

type AccountSetupViewsTypes = 'firstUserSetupPassword' | 'inviteOrResetUserSetupPassword' | 'login' | 'general-info';
type CustomErrorsTypes = 'maintenance-mode' | 'sso-failure';
type CustomInfoTypes = 'account-deleted';

const bindClasses = classNameBind.bind(styles);

const ErrorTemplate = ({
  image,
  children,
}: {
  image: string
  children: React.ReactNode
}) => (
  <div className={bindClasses('wrapper')}>
    <Card>
      <Card.Section>
        <div className={bindClasses('img-wrapper')}>
          <Image src={image} alt="Internal server error" />
        </div>
      </Card.Section>
      <Card.Section>
        <Stack vertical horizontalAlignment="left">
          {children}
        </Stack>
      </Card.Section>
    </Card>
  </div>
);

interface InjectedProps {
  astronAuthApi: AstronAuthService
}

interface Props extends InjectedProps {
  reason: typeof httpErrors | CustomErrorsTypes | CustomInfoTypes | string
  viewType: AccountSetupViewsTypes
  history: History
  t: TFunction
}

export const Errors = withTranslation(['astronauth'])(withInject<InjectedProps>({
  astronAuthApi: 'AstronAuthCrate.astronAuth',
  history: 'RouterCrate.history',
})(({
  reason,
  viewType = 'inviteOrResetUserSetupPassword',
  history,
  astronAuthApi,
  t,
}: Props) => {
  if (reason instanceof httpErrors.GoneError) {
    if (viewType === 'firstUserSetupPassword') {
      return (
        <ErrorTemplate image={expired}>
          <Stack.Item fill>
            <div style={{ textAlign: 'center' }}>
              <Header type="small">
                {t('general.errorViews.linkExpired.header')}
              </Header>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Text>
              {t('general.errorViews.linkExpired.description')}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Button
              appearance="primary"
              text={t('general.errorViews.linkExpired.button.text')}
              onClick={() => {
                window.location.href = 'https://piwik.pro/core-plan';
              }}
            />
          </Stack.Item>
        </ErrorTemplate>
      );
    }

    return (
      <ErrorTemplate image={noLongerValid}>
        <Stack.Item fill>
          <div style={{ textAlign: 'center' }}>
            <Header type="small">
              {t('general.errorViews.linkUsed.header')}
            </Header>
          </div>
        </Stack.Item>
        <Stack.Item>
          <Text>
            {t('general.errorViews.linkUsed.description.line1')}
            <div className={bindClasses('list-wrapper')}>
              <ul>
                <li>
                  <Trans
                    t={t}
                    i18nKey="general.errorViews.linkUsed.description.line2"
                    components={[
                      <Link
                        onClick={() => history.push(RESET_PASSWORD_URL)}
                      >
                        reset link
                      </Link>,
                    ]}
                  />
                </li>
                <li>{t('general.errorViews.linkUsed.description.line3')}</li>
              </ul>
            </div>
          </Text>
        </Stack.Item>
      </ErrorTemplate>
    );
  }

  if (reason instanceof httpErrors.NotFoundError) {
    if (viewType === 'firstUserSetupPassword') {
      return (
        <ErrorTemplate image={alreadyUsed}>
          <Stack.Item fill>
            <div style={{ textAlign: 'center' }}>
              <Header type="small">
                {t('general.errorViews.linkNotFound.header')}
              </Header>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Text>
              {t('general.errorViews.linkNotFound.description')}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Stack spacing="narrow">
              <Stack.Item>
                <Button
                  appearance="primary"
                  text={t('general.errorViews.linkNotFound.loginBtnText')}
                  onClick={() => history.push(LOGIN_URL)}
                />
              </Stack.Item>
              <Stack.Item>
                <Button
                  appearance="simple"
                  text={t('general.errorViews.linkNotFound.forgotPasswordBtnText')}
                  onClick={() => history.push(RESET_PASSWORD_URL)}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </ErrorTemplate>
      );
    }

    return (
      <ErrorTemplate image={noLongerValid}>
        <Stack.Item fill>
          <div style={{ textAlign: 'center' }}>
            <Header type="small">
              {t('general.errorViews.linkUsed.header')}
            </Header>
          </div>
        </Stack.Item>
        <Stack.Item>
          <Text>
            {t('general.errorViews.linkUsed.description.line1')}
            <div className={bindClasses('list-wrapper')}>
              <ul>
                <li>
                  <Trans
                    t={t}
                    i18nKey="general.errorViews.linkUsed.description.line2"
                    components={[
                      <Link
                        onClick={() => history.push(RESET_PASSWORD_URL)}
                      >
                        reset link
                      </Link>,
                    ]}
                  />
                </li>
                <li>{t('general.errorViews.linkUsed.description.line3')}</li>
              </ul>
            </div>
          </Text>
        </Stack.Item>
      </ErrorTemplate>
    );
  }

  if (viewType === 'login') {
    const redirectPath = astronAuthApi.getSafeAbsoluteRedirectPath(useQuery('RelayState'));

    if (reason === 'maintenance-mode') {
      return (
        <ErrorTemplate image={maintenanceMode}>
          <Stack.Item fill>
            <div style={{ textAlign: 'center' }}>
              <Header type="small">
                {t('general.errorViews.maintenanceMode.header')}
              </Header>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Text>
              {t('general.errorViews.maintenanceMode.description')}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Stack spacing="narrow">
              <Stack.Item>
                <Button
                  appearance="primary"
                  text={t('general.errorViews.maintenanceMode.loginBtnText')}
                  onClick={() => astronAuthApi.loginWithSaml({ redirectPath })}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </ErrorTemplate>
      );
    }

    if (reason === 'sso-failure') {
      return (
        <ErrorTemplate image={internalServerError}>
          <Stack.Item fill>
            <div style={{ textAlign: 'center' }}>
              <Header type="small">
                {t('general.errorViews.sooFailure.header')}
              </Header>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Text>
              {t('general.errorViews.sooFailure.description')}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Stack spacing="narrow">
              <Stack.Item>
                <Button
                  appearance="primary"
                  text={t('general.errorViews.maintenanceMode.loginBtnText')}
                  onClick={() => astronAuthApi.loginWithSaml({ redirectPath })}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </ErrorTemplate>
      );
    }
  }

  if (viewType === 'general-info') {
    if (reason === 'account-deleted') {
      return (
        <ErrorTemplate image={accountDeleted}>
          <Stack.Item fill>
            <div style={{ textAlign: 'center' }}>
              <Header type="small">
                {t('general.infoViews.accountDeleted.header')}
              </Header>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Text>
              <Trans
                t={t}
                i18nKey="general.infoViews.accountDeleted.text"
                components={[
                  <Link href="https://piwik.pro/core-plan/">piwik.pro</Link>,
                ]}
              />
            </Text>
          </Stack.Item>
        </ErrorTemplate>
      );
    }
  }

  return (
    <ErrorTemplate image={internalServerError}>
      <Stack.Item>
        <div style={{ textAlign: 'center' }}>
          <Header type="small">
            {t('general.errorViews.internalError.header')}
          </Header>
        </div>
      </Stack.Item>
      <Stack.Item>
        <Text>
          {t('general.errorViews.internalError.description')}
        </Text>
      </Stack.Item>
    </ErrorTemplate>
  );
}));
