import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FormikProps } from 'formik';
import { History } from 'history';
import { TFunction } from 'react-i18next';
import { withInject } from '@piwikpro/platform';
import { FormValidator } from '@piwikpro/form-crate';
import {
  Stack,
  Header,
  Form,
  TextFieldControl,
  Information,
  Link,
  withTranslation,
} from '@piwikpro/ui-components';
import { useKeyLock } from '../../../../hooks/useKeyLock';
import { AstronAuthService } from '../../../../services/AstronAuth';
import { RESET_PASSWORD_URL } from '../../../../constants';
import { IAstronAuthState } from '../../../../reducers';

const mapStateToProps = (state: IAstronAuthState) => ({
  failed: state.authProcess.failed,
  failReason: state.authProcess.failReason,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

interface InjectedProps {
  validation: FormValidator
  astronAuthApi: AstronAuthService
  history: History
}

interface Props extends ReduxProps, InjectedProps {
  t: TFunction
}

interface IFormValues {
  email: string
  password: string
}

export const PasswordInput = ({
  validation,
  astronAuthApi,
  history,
  failed,
  failReason,
  t,
}: Props) => {
  const [formAutofilled, setFormAutofilled] = useState(false);
  const { search } = document.location;
  const capsLockEnabled = useKeyLock('CapsLock');
  const incorrectCredentials = failReason === 'UnauthorizedRequestError';
  const rateLimitExceeded = failReason === 'TooManyRequestsError';
  const otherFailReason = !(incorrectCredentials || rateLimitExceeded);

  useEffect(() => {
    setTimeout(() => {
      if (document.querySelector('input:-webkit-autofill')) {
        setFormAutofilled(true);
      }
    }, 1000); // delay required for Chrome, to work after direct url entry in new tab
  }, []);

  const handleSubmit = async (values: IFormValues, api: FormikProps<IFormValues>) => {
    try {
      await astronAuthApi.login(values);

      api.setSubmitting(false);
    } catch {
      api.setSubmitting(false);
    }
  };

  return (
    <Stack vertical horizontalAlignment="middle">
      <Stack.Item>
        <Header type="small">
          {t('login.passwordInput.header')}
        </Header>
      </Stack.Item>
      <Stack.Item fill>
        <Form
          id="log-in-form"
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validation.createValidationSchema(validators => ({
            email: validators.users.email,
          }))}
          onSubmit={handleSubmit}
          render={({
            setFieldValue, isValid, validateForm, setFieldTouched, values,
          }: FormikProps<IFormValues>) => (
            <Stack vertical>
              {failed && (
                <Stack.Item fill>
                  <Information type="error">
                    {incorrectCredentials && t('login.passwordInput.errors.incorrectCredentials')}
                    {rateLimitExceeded && t('general.notifications.errors.rateLimit.text')}
                    {otherFailReason && t('login.passwordInput.errors.other')}
                  </Information>
                </Stack.Item>
              )}
              <Stack.Item fill>
                <TextFieldControl
                  name="email"
                  type="text"
                  label={t('login.passwordInput.emailInput.label')}
                  onChange={(e: { target: { name: string, value: string } }) => {
                    setFormAutofilled(false);
                    setFieldValue('email', e.target.value);
                    setFieldTouched('email', true);
                  }}
                  onBlur={() => validateForm(values)}
                  autoFocus
                />
              </Stack.Item>
              <Stack.Item fill>
                <TextFieldControl
                  name="password"
                  type="password"
                  label={t('login.passwordInput.passwordInput.label')}
                  onChange={(e: { target: { name: string, value: string } }) => {
                    setFormAutofilled(false);
                    setFieldValue('password', e.target.value);
                  }}
                  info={capsLockEnabled && t('login.passwordInput.passwordInput.info.capslock')}
                  passwordPreview
                />
              </Stack.Item>
              <Stack.Item>
                <div style={{ marginTop: '-12px' }}>
                  <Link
                    size="small"
                    onClick={() => history.push(`${RESET_PASSWORD_URL}${search}`)}
                  >
                    {t('login.passwordInput.passwordInput.forgetPasswordBtn')}
                  </Link>
                </div>
              </Stack.Item>
              <Stack.Item fill>
                <Form.Submit
                  fullWidth
                  text={t('login.passwordInput.passwordInput.loginBtn')}
                  disabledOverride={(() => {
                    if (isValid) {
                      return false;
                    }

                    if (formAutofilled) {
                      return false;
                    }

                    return true;
                  })()}
                />
              </Stack.Item>
            </Stack>
          )}
        />
      </Stack.Item>
    </Stack>
  );
};

export default connector(withInject<InjectedProps>({
  history: 'RouterCrate.history',
  astronAuthApi: 'AstronAuthCrate.astronAuth',
  validation: 'FormCrate.validation',
})(withTranslation(['astronauth'])(PasswordInput)));
