import 'babel-polyfill'; // TODO: remove due to lack of support for IE 11
import 'reflect-metadata';
import '@piwikpro/ui-components/components.css';
import {
  Platform,
} from '@piwikpro/platform';
import { AstronAuthCrate } from './AstronAuthCrate';

const platform = new Platform();

platform.run(AstronAuthCrate as any);
