import React from 'react';
import {
  Stack, Text, UnorderedList, useTranslation,
} from '@piwikpro/ui-components';

export const SamlDeleteModal = () => {
  const { t } : { t: (key: string) => string } = useTranslation();

  return (
    <Stack spacing="narrow" vertical>
      <Stack.Item>
        <Text>{t('administration:accountSecurity.saml-delete.modal.message')}</Text>
      </Stack.Item>
      <Stack.Item previousSpacing="none">
        <UnorderedList>
          <UnorderedList.Item>
            <Text>{t('administration:accountSecurity.saml-delete.modal.line1')}</Text>
          </UnorderedList.Item>
          <UnorderedList.Item>
            <Text>{t('administration:accountSecurity.saml-delete.modal.line2')}</Text>
          </UnorderedList.Item>
          <UnorderedList.Item>
            <Text>{t('administration:accountSecurity.saml-delete.modal.line3')}</Text>
          </UnorderedList.Item>
        </UnorderedList>
      </Stack.Item>
      <Stack.Item>
        <Text>{t('administration:accountSecurity.saml-delete.modal.message3')}</Text>
      </Stack.Item>
      <Stack.Item>
        <Text>{t('administration:accountSecurity.saml-delete.modal.question')}</Text>
      </Stack.Item>
    </Stack>
  );
};
