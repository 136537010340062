/* eslint-disable camelcase */
import { handleActions, createAction } from 'redux-actions';
import { SignatureValidations, IdentityProviderPayload } from '../../interfaces';

export interface OrganizationAuthSettingsState {
  twoFactorAuth: {
    enforced: boolean
  }
  saml: {
    available: boolean
    verified: boolean
    enabled: boolean
    serviceProvider: {
      signatureValidation: SignatureValidations
    }
    identityProvider: IdentityProviderPayload
    metadata: {
      isLoading: boolean
      failed: boolean
      value: string
    }
  }
  session: {
    inactivityTimeout: number
  }
  support: {
    enabled: boolean
  }
  autoProvisioning: {
    enabled: boolean
  }
  isLoading: boolean
  failed: boolean
}

const initialState: OrganizationAuthSettingsState = {
  twoFactorAuth: {
    enforced: false,
  },
  saml: {
    available: false,
    verified: false,
    enabled: false,
    serviceProvider: {
      signatureValidation: 'assertion',
    },
    identityProvider: {
      sign_on_url: '',
      entity_id: '',
      x509_certificate: '',
    },
    metadata: {
      isLoading: true,
      failed: false,
      value: '',
    },
  },
  session: {
    inactivityTimeout: 30,
  },
  support: {
    enabled: false,
  },
  autoProvisioning: {
    enabled: false,
  },
  isLoading: true,
  failed: false,
};

export const getAuthSettingsForOrganizationInitialized = createAction(
  '@platform/query/auth/settingsForOrganization/get/init',
);
export const getAuthSettingsForOrganizationSucceeded = createAction(
  '@platform/query/auth/settingsForOrganization/get/success',
  (payload: any) => payload,
);
export const getAuthSettingsForOrganizationFailed = createAction(
  '@platform/query/auth/settingsForOrganization/get/failed',
);
export const updateAuthSettingsForOrganizationSucceeded = createAction(
  '@platform/command/auth/settingsForOrganization/update/success',
);
export const updateAuthSettingsForOrganizationFailed = createAction(
  '@platform/command/auth/settingsForOrganization/update/failed',
);
export const getSamlMetadataInitialized = createAction(
  '@platform/query/auth/samlMetadata/get/init',
);
export const getSamlMetadataSucceeded = createAction(
  '@platform/query/auth/samlMetadata/get/success',
  (payload: any) => payload,
);
export const getSamlMetadataFailed = createAction(
  '@platform/query/auth/samlMetadata/get/failed',
);
export const testSamlSettingsInitialized = createAction(
  '@platform/command/auth/samlSettings/test/init',
);
export const testSamlSettingsSucceeded = createAction(
  '@platform/command/auth/samlSettings/test/success',
);
export const testSamlSettingsFailed = createAction(
  '@platform/command/auth/samlSettings/test/failed',
);
export const deleteSamlSettingsInitialized = createAction(
  '@platform/command/auth/samlSettings/delete/init',
);
export const deleteSamlSettingsSucceeded = createAction(
  '@platform/command/auth/samlSettings/delete/success',
);
export const deleteSamlSettingsFailed = createAction(
  '@platform/command/auth/samlSettings/delete/failed',
);

export default handleActions<OrganizationAuthSettingsState, any>({
  [getAuthSettingsForOrganizationInitialized as any]: state => ({
    ...state,
    isLoading: true,
    failed: false,
  }),
  [getAuthSettingsForOrganizationSucceeded as any]: (state, { payload }: any) => ({
    ...state,
    isLoading: false,
    failed: false,
    twoFactorAuth: {
      enforced: payload['2fa'].enforced,
    },
    saml: {
      ...state.saml,
      available: !!payload.saml,
      enabled: payload.saml?.enabled || false,
      verified: payload.saml?.verified || false,
      serviceProvider: payload.saml?.service_provider
        ? { signatureValidation: payload.saml.service_provider.signature_validation }
        : initialState.saml.serviceProvider,
      identityProvider: payload.saml?.identity_provider || initialState.saml.identityProvider,
    },
    autoProvisioning: {
      enabled: payload.auto_provisioning.enabled,
    },
    session: {
      inactivityTimeout: payload.session.inactivity_timeout,
    },
    support: payload.support,
  }),
  [getAuthSettingsForOrganizationFailed as any]: state => ({
    ...state,
    isLoading: false,
    failed: true,
  }),
  [getSamlMetadataInitialized as any]: state => ({
    ...state,
    saml: {
      ...state.saml,
      metadata: {
        isLoading: true,
        failed: false,
        value: '',
      },
    },
  }),
  [getSamlMetadataSucceeded as any]: (state, { payload }) => ({
    ...state,
    saml: {
      ...state.saml,
      metadata: {
        isLoading: false,
        failed: false,
        value: payload,
      },
    },
  }),
  [getSamlMetadataFailed as any]: state => ({
    ...state,
    saml: {
      ...state.saml,
      metadata: {
        isLoading: false,
        failed: true,
        value: '',
      },
    },
  }),
}, initialState);
