import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export default (yup: typeof Yup, t: TFunction) => ({
  name: yup.string()
    .max(191)
    .required(),
  users: yup.array().required(),
  externalId: yup.string().required().max(191, t('user-groups.assign-external-id.validation.errors.too-long', { number: 191 })),
});
