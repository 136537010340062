import React, { useState } from 'react';
import { TFunction } from 'react-i18next';
import { FormikProps } from 'formik';
import { History } from 'history';
import classNameBind from 'classnames/bind';
import { withInject } from '@piwikpro/platform';
import { FormValidator } from '@piwikpro/form-crate';
import {
  Stack,
  Header,
  Text,
  Form,
  Icon,
  Link,
  Information,
  TextFieldControl,
  Divider,
  withTranslation,
} from '@piwikpro/ui-components';
import { AstronAuthService } from '../../../services/AstronAuth';
import styles from './RequestResetForm.module.css';
import { LOGIN_URL } from '../../../constants';

const bindClasses = classNameBind.bind(styles);

interface InjectedProps {
  validation: FormValidator
  astronAuthApi: AstronAuthService
  history: History
}

interface Props extends InjectedProps {
  t: TFunction
}

interface IFormValues {
  email: string
}

export const RequestResetForm = ({
  validation,
  astronAuthApi,
  history,
  t,
}: Props) => {
  const { search } = document.location;
  const [showResetInfo, setResetShowInfo] = useState(false);
  const [failReason, setFailReason] = useState(null);
  const invalidRequestError = failReason === 'InvalidRequestError';
  const rateLimitExceeded = failReason === 'TooManyRequestsError';
  const otherFailReason = !(invalidRequestError || rateLimitExceeded);

  const handleSubmit = async (
    values: IFormValues,
    api: FormikProps<IFormValues>,
  ) => {
    try {
      setFailReason(null);

      await astronAuthApi.resetPasswordRequest(values);
      setResetShowInfo(true);
      api.setSubmitting(false);
    } catch (err: any) {
      api.resetForm();
      api.setSubmitting(false);
      setFailReason(err.name);
    }
  };

  if (showResetInfo) {
    return (
      <Stack vertical horizontalAlignment="middle">
        <Stack.Item>
          <Stack vertical horizontalAlignment="middle">
            <Stack.Item>
              <Icon size="large" color="green" name="checkmark-circle" />
            </Stack.Item>
            <Stack.Item>
              <Header type="small">
                {t('resetPassword.requestResetForm.successScreen.header')}
              </Header>
            </Stack.Item>
            <Stack.Item previousSpacing="narrow">
              <Text>
                {t('resetPassword.requestResetForm.successScreen.text.line1')}
              </Text>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item fill>
          <Divider />
        </Stack.Item>
        <Stack.Item>
          <Stack vertical horizontalAlignment="left" fullWidth>
            <Stack.Item>
              <Stack spacing="narrow" verticalAlignment="center">
                <Stack.Item>
                  <Icon size="small" color="blue" name="info-circle" />
                </Stack.Item>
                <Stack.Item>
                  <Text type="strong">
                    {t('resetPassword.requestResetForm.successScreen.text.line2')}
                  </Text>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item previousSpacing="narrow">
              <Text>
                {t('resetPassword.requestResetForm.successScreen.text.line3')}
              </Text>
            </Stack.Item>
            <Stack.Item>
              <ul className={bindClasses('list')}>
                <li>
                  <Text>
                    {t('resetPassword.requestResetForm.successScreen.text.line4')}
                  </Text>
                </li>
                <li>
                  <Text>
                    {t('resetPassword.requestResetForm.successScreen.text.line5')}
                    <Link onClick={() => setResetShowInfo(false)}>
                      {t('resetPassword.requestResetForm.successScreen.text.resendLink')}
                    </Link>
                  </Text>
                </li>
                <li>
                  <Text>{t('resetPassword.requestResetForm.successScreen.text.line6')}</Text>
                </li>
              </ul>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    );
  }

  return (
    <Stack vertical horizontalAlignment="middle">
      <Stack.Item>
        <Header type="small">
          {t('resetPassword.requestResetForm.header')}
        </Header>
      </Stack.Item>
      <Stack.Item fill previousSpacing="narrow">
        {/* TODO: verify subtitle component here to adjust left */}
        <div style={{ textAlign: 'center' }}>
          <Text>
            {t('resetPassword.requestResetForm.description')}
          </Text>
        </div>
      </Stack.Item>
      {failReason && (
        <Stack.Item fill>
          <Information type="error">
            {invalidRequestError && t('resetPassword.requestResetForm.invalidEmailError')}
            {rateLimitExceeded && t('general.notifications.errors.rateLimit.text')}
            {otherFailReason && t('login.passwordInput.errors.other')}
          </Information>
        </Stack.Item>
      )}
      <Stack.Item fill>
        <Form
          id="reset-password-input"
          initialValues={{
            email: '',
          }}
          validationSchema={validation.createValidationSchema(validators => ({
            email: validators.users.email,
          }))}
          onSubmit={handleSubmit}
          render={({
            setFieldValue, validateForm, setFieldTouched, values,
          }: FormikProps<IFormValues>) => (
            <Stack vertical spacing="narrow">
              <Stack.Item fill>
                <TextFieldControl
                  label={t('resetPassword.requestResetForm.emailInput.label')}
                  name="email"
                  type="text"
                  onChange={(e) => {
                    setFieldValue('email', e.target.value);
                    setFieldTouched('email', true);
                  }}
                  onBlur={() => validateForm(values)}
                  autoFocus
                />
              </Stack.Item>
              <Stack.Item />
              <Stack.Item fill>
                <Form.Submit text={t('resetPassword.requestResetForm.confirmBtn.text')} fullWidth />
              </Stack.Item>
              <Stack.Item fill>
                <Form.Cancel
                  text={t('resetPassword.requestResetForm.returnBtn.text')}
                  appearance="simple"
                  onClick={() => history.push(`${LOGIN_URL}${search}`)}
                  fullWidth
                />
              </Stack.Item>
            </Stack>
          )}
        />
      </Stack.Item>
    </Stack>
  );
};

export default withInject<InjectedProps>({
  astronAuthApi: 'AstronAuthCrate.astronAuth',
  history: 'RouterCrate.history',
  validation: 'FormCrate.validation',
})(withTranslation(['astronauth'])(RequestResetForm));
