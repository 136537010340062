import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TFunction } from 'react-i18next';
import { FormikProps } from 'formik';
import { useRouteMatch } from 'react-router';
import { History } from 'history';
import classNameBind from 'classnames/bind';
import {
  View,
  Layout,
  Card,
  Stack,
  Form,
  TextFieldControl,
  Spinner,
  Text,
  Subtitle,
  Caption,
  Link,
  Icon,
  Header,
  TextStyle,
  Information,
  Trans,
  withTranslation,
  Divider,
} from '@piwikpro/ui-components';
import { ConfigService, withInject } from '@piwikpro/platform';
import { FormValidator } from '@piwikpro/form-crate';
import { Users } from '@piwikpro/users-crate';
import Errors from '../../Errors';
import styles from '../index.module.css';
import { IAstronAuthState } from '../../../reducers';

const bindClasses = classNameBind.bind(styles);

const mapStateToProps = (state: IAstronAuthState) => ({
  isLoading: state.setPasswordProcess.isLoading,
  failed: state.setPasswordProcess.failed,
  failReason: state.setPasswordProcess.failReason,
  passwordCode: state.setPasswordProcess.passwordCode,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

interface InjectedProps {
  users: Users
  validation: FormValidator
  config: ConfigService
  history: History
}

interface Props extends ReduxProps, InjectedProps {
  t: TFunction
}

export interface IInvitedUserSetupPasswordForm {
  password: string
  passwordRepeated: string
  setPasswordCode: string
  informationError?: string
}

const Success = ({ t } : { t: TFunction }) => (
  <Card>
    <Card.Section>
      <Stack vertical horizontalAlignment="middle">
        <Stack.Item>
          <Icon size="large" color="green" name="checkmark-circle" />
        </Stack.Item>
        <Stack.Item>
          <Header type="small">
            {t('invitedUser.successScreen.header')}
          </Header>
        </Stack.Item>
        <Stack.Item previousSpacing="narrow">
          <TextStyle>
            {t('invitedUser.successScreen.description')}
          </TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  </Card>
);

export const InvitedUserSetupPassword: React.FC<Props> = ({
  validation,
  isLoading,
  failReason,
  failed,
  passwordCode,
  history,
  users,
  config,
  t,
}) => {
  const [passwordSetWithSuccess, setPasswordSetWithSuccess] = useState(false);
  const { params } = useRouteMatch<{
    setPasswordCode?: string
  }>();
  const userType = 'invited';
  const checkUserSetup = async () => {
    try {
      await users.checkUserSetup(params.setPasswordCode || '');
    // eslint-disable-next-line no-empty
    } catch {}
  };

  useEffect(() => {
    checkUserSetup();
  }, []);

  const handleSubmit = async (values: IInvitedUserSetupPasswordForm, {
    setSubmitting,
    setErrors,
  }: FormikProps<IInvitedUserSetupPasswordForm>) => {
    try {
      await users.setupUser({
        setPasswordCode: passwordCode,
        password: values.password,
        userType,
      });

      setPasswordSetWithSuccess(true);

      setTimeout(() => {
        // TODO: should we delay that for success screen
        // and which should be url of destination (analytics currently)
        window.location.assign(config.get('ACCOUNT_SETUP_INVITED_USER_REDIRECT_PATH'));
      }, 3000);
    } catch (err: any) {
      if (err.name === 'TooManyRequestsError') {
        setErrors({ informationError: t('general.notifications.errors.rateLimit.text') });
      } else {
        setErrors({
          ...err.errors,
          informationError: t('general.notifications.errors.generic.text'),
        });
      }
    }

    setSubmitting(false);
  };

  return (
    <Stack vertical horizontalAlignment="middle" verticalAlignment="center" fullHeight>
      <Stack.Item>
        <Layout maxWidth="narrow">
          <Layout.Column>
            <div className={bindClasses('flex-wrapper')}>
              <div className={bindClasses('width-wrapper')}>
                {isLoading && <Spinner className="large" />}
                {failed && (
                  <Errors
                    viewType="inviteOrResetUserSetupPassword"
                    reason={failReason}
                    history={history}
                  />
                )}
                {passwordSetWithSuccess && <Success t={t} />}
                {!isLoading && !failReason && !passwordSetWithSuccess && (
                  <Form
                    id="account-setup-invited-user-setup-password-form"
                    initialValues={{
                      password: '',
                      passwordRepeated: '',
                    } as IInvitedUserSetupPasswordForm}
                    validationSchema={validation.createValidationSchema((validators: any) => ({
                      password: validators.users.password,
                      passwordRepeated: validators.users.passwordRepeated,
                    }))}
                    onSubmit={handleSubmit}
                    render={({
                      errors,
                      validateForm,
                      setFieldValue,
                      setFieldTouched,
                      values,
                    }: FormikProps<IInvitedUserSetupPasswordForm>) => (
                      <View>
                        <View.Body>
                          <Card>
                            <Card.Section>
                              <Stack vertical>
                                <Stack.Item fill>
                                  <Subtitle>
                                    {t('invitedUser.setPasswordForm.header')}
                                  </Subtitle>
                                </Stack.Item>
                                <Stack.Item previousSpacing="narrow">
                                  <Text>
                                    {t('invitedUser.setPasswordForm.description')}
                                  </Text>
                                </Stack.Item>
                                {errors.informationError && (
                                  <Stack.Item fill>
                                    <Information type="error">
                                      {errors.informationError}
                                    </Information>
                                  </Stack.Item>
                                )}
                                <Stack.Item fill>
                                  <TextFieldControl
                                    name="password"
                                    type="password"
                                    label={t('invitedUser.setPasswordForm.password.label')}
                                    info={t('invitedUser.setPasswordForm.password.info')}
                                    required
                                    onBlur={() => validateForm(values)}
                                    onChange={(e: {target: { name: string, value: string }}) => {
                                      setFieldValue('password', e.target.value);
                                      setFieldTouched('password', true);
                                    }}
                                    autoFocus
                                  />
                                </Stack.Item>
                                <Stack.Item fill>
                                  <TextFieldControl
                                    name="passwordRepeated"
                                    type="password"
                                    label={t('invitedUser.setPasswordForm.passwordRepeat.label')}
                                    required
                                    onChange={(e: {target: { name: string, value: string }}) => {
                                      setFieldValue('passwordRepeated', e.target.value);
                                      setFieldTouched('passwordRepeated', true);
                                    }}
                                  />
                                </Stack.Item>
                                <Stack.Item fill>
                                  <Form.Submit fullWidth text={t('invitedUser.setPasswordForm.confirmBtn')} />
                                </Stack.Item>
                                <Stack.Item fill>
                                  <Divider noMargin />
                                </Stack.Item>
                                <Stack.Item fill>
                                  <Caption subdued>
                                    <div style={{ textAlign: 'center' }}>
                                      <Trans
                                        t={t}
                                        i18nKey="invitedUser.setPasswordForm.privacyPolicyTextInfo"
                                        components={[
                                          <Link size="small" href="https://piwik.pro/privacy-policy/">
                                            privacy policy
                                          </Link>,
                                        ]}
                                      />
                                    </div>
                                  </Caption>
                                </Stack.Item>
                              </Stack>
                            </Card.Section>
                          </Card>
                        </View.Body>
                      </View>
                    )}
                  />
                )}
              </div>
            </div>
          </Layout.Column>
        </Layout>
      </Stack.Item>
    </Stack>
  );
};

export default connector(withInject<InjectedProps>({
  users: 'UsersCrate.users',
  validation: 'FormCrate.validation',
  config: 'config',
  history: 'RouterCrate.history',
})(
  withTranslation(['astronauth'])(InvitedUserSetupPassword),
));
