/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { map } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { ConfigService, Crate, inject } from '@piwikpro/platform';
import productAnalytics from '@piwikpro/product-analytics';
import FormCrate from '@piwikpro/form-crate';
import UsersCrate from '@piwikpro/users-crate';
import TranslationCrate from '@piwikpro/translation-crate';
import RouterCrate from '@piwikpro/router-crate';
import NotificationCrate from '@piwikpro/notification-crate';
import AuthCrate from '@piwikpro/auth-crate';
import OrganizationCrate from '@piwikpro/organization-crate';
import HttpCrate, { HttpClient, MaintenanceInterceptor } from '@piwikpro/http-crate';
import AstronAuth from './AstronAuth';
import { AstronAuthService } from './services/AstronAuth';
import {
  authProcess,
  setPasswordProcess,
  resetPasswordProcess,
  confirmReservationProcess,
} from './reducers';
import PPMSSnippetPanel from './PPMSSnippetPanel';

declare global {
  interface Window {
    dataLayer: any[]
  }
}

@Crate({
  name: 'AstronAuthCrate',
  imports: [
    AuthCrate,
    TranslationCrate,
    OrganizationCrate,
    NotificationCrate,
    RouterCrate,
    UsersCrate,
    HttpCrate,
    FormCrate,
  ],
  services: [
    {
      name: 'astronAuth', provide: AstronAuthService,
    },
  ],
  reducers: {
    authProcess,
    setPasswordProcess,
    resetPasswordProcess,
    confirmReservationProcess,
  },
  reduxMiddleware: [
    productAnalytics.createReduxMiddleware(),
  ],
  registry: (bind: any) => {
    bind('productAnalytics').toConstantValue(productAnalytics);

    productAnalytics.getStream()
    .pipe(map(e => ({ type: e.type })))
    .subscribe((event: { type: string }) => {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
          event: event.type,
      });
    });
  },
  panels: [PPMSSnippetPanel],
  bootstrap: AstronAuth,
})

export class AstronAuthCrate {
  constructor(
    @inject('HttpCrate.httpClient') private httpClient: HttpClient,
    @inject('HttpCrate.interceptors.MaintenanceInterceptor') private maintenanceInterceptor: MaintenanceInterceptor,
  ) {}

  static async configure(config: ConfigService) {
    if (process.env.NODE_ENV !== 'test') {
      await config.load('/user-panel/config.json');
      productAnalytics.pushToDataLayer({ ppasVersion: config.get('PPAS_VERSION') });

      const sentryDsn = config.get('SENTRY_DSN');
      if (sentryDsn) {
        Sentry.init({
          dsn: sentryDsn,
          release: config.get('PPAS_VERSION'),
          environment: config.get('SENTRY_ENVIRONMENT'),
        });
        Sentry.setTag('logger', 'mid-astronauth-ui');
      }
    }
  }

  async onInit() {
    this.httpClient.addInterceptors([
      this.maintenanceInterceptor,
    ]);
  }
}
