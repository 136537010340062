/* eslint-disable camelcase */
import { createAction, handleActions } from 'redux-actions';

export type AuthStepsType =
  'totp/configure'
  | 'totp/input-code'
  | 'totp/backup-codes'
  | 'totp/backup-code-input'
  | 'password/input-credentials'
  | 'saml/login'
  | null;

export interface IAuthProcessData {
  backup_codes: string []
  totp_uri: string
}

export interface IAuthProcessState {
  isLoading: boolean
  failed: boolean
  failReason: string
  authenticated: boolean
  step: AuthStepsType
  data: Partial<IAuthProcessData>
}

export interface IAuthProcessResponse {
  next: AuthStepsType
  authenticated: boolean
  data: Partial<IAuthProcessData>
}

export const authProcessStepFetchInitialized = createAction(
  '@astronauth/query/authProcessStep/get/init',
);

export const authProcessStepFetchSucceeded = createAction<
IAuthProcessResponse, IAuthProcessResponse
>(
  '@astronauth/query/authProcessStep/get/success',
  payload => payload,
);

export const authProcessStepFetchFailed = createAction<IAuthProcessResponse, IAuthProcessResponse>(
  '@astronauth/query/authProcessStep/get/failed',
  payload => payload,
);

const initialState: IAuthProcessState = {
  isLoading: true,
  failed: false,
  failReason: '',
  authenticated: false,
  step: null,
  data: {},
};

type AuthProcessPayload = IAuthProcessResponse & string;

export default handleActions<IAuthProcessState, AuthProcessPayload>({
  [authProcessStepFetchInitialized.toString()]: () => ({
    ...initialState,
  }),
  [authProcessStepFetchSucceeded.toString()]: (
    state,
    { payload },
  ) => ({
    ...state,
    isLoading: false,
    failed: false,
    authenticated: payload.authenticated,
    step: payload.next,
    data: payload.data,
  }),
  [authProcessStepFetchFailed.toString()]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    failed: true,
    authenticated: false,
    failReason: payload,
  }),
}, initialState);
