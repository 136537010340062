import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TFunction } from 'react-i18next';
import { FormikProps } from 'formik';
import { History } from 'history';
import { useRouteMatch } from 'react-router';
import classNameBind from 'classnames/bind';
import {
  Form,
  TextFieldControl,
  View,
  Spinner,
  Layout,
  Text,
  Subtitle,
  Stack,
  withTranslation,
  Information,
} from '@piwikpro/ui-components';
import { ConfigService, withInject } from '@piwikpro/platform';
import { Users } from '@piwikpro/users-crate';
import productAnalytics from '@piwikpro/product-analytics';
import { Organization } from '@piwikpro/organization-crate';
import { FormValidator } from '@piwikpro/form-crate';
import { FIRST_USER_LOADING_SCREEN_DELAY } from '../../../constants';
import { delayAction } from '../../../utils';
import styles from '../index.module.css';
import { useQuery } from '../../../hooks/useQuery';
import Errors from '../../Errors';
import NoMarketingViewContainer from '../../../components/NoMarketingViewContainer';
import Marketing from '../../Marketing';
import { IAstronAuthState } from '../../../reducers';

const bindClasses = classNameBind.bind(styles);

const mapStateToProps = (state: IAstronAuthState) => ({
  isLoading: state.confirmReservationProcess.isLoading,
  failed: state.confirmReservationProcess.failed,
  failReason: state.confirmReservationProcess.failReason,
  passwordCode: state.confirmReservationProcess.passwordCode,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

interface InjectedProps {
  organization: Organization
  users: Users
  validation: FormValidator
  config: ConfigService
  history: History
}

interface Props extends ReduxProps, InjectedProps {
  t: TFunction
}

export interface IFirstUserAccountSetupPasswordForm {
  password: string
  passwordRepeated: string
  informationError?: string
}

const Loading = ({ t }: { t: TFunction }) => (
  <Stack vertical horizontalAlignment="middle" fullHeight>
    <Stack.Item>
      <Stack.Item>
        <Spinner className="x-large" />
      </Stack.Item>
    </Stack.Item>
    <Stack.Item previousSpacing="wide">
      <Subtitle>
        {t('firstUser.loadingScreen.header')}
      </Subtitle>
    </Stack.Item>
    <Stack.Item>
      <Text>
        {t('firstUser.loadingScreen.description')}
      </Text>
    </Stack.Item>
  </Stack>
);

export const FirstUserSetupPassword = ({
  organization,
  isLoading,
  failed,
  failReason,
  passwordCode,
  history,
  users,
  config,
  validation,
  t,
}: Props) => {
  const [passwordSetWithSuccess, setPasswordSetWithSuccess] = useState(false);
  const { url, params } = useRouteMatch<{
    reservationCode?: string
    setPasswordCode?: string
  }>();
  const email = useQuery('product_analytics_email' || '');
  const isOnSetPasswordPath = url.includes('password');
  let correctSetPasswordCode = passwordCode;
  const userType = 'first';

  const checkReservation = async () => {
    try {
      await delayAction(async () => {
        await organization.checkReservation(params.reservationCode || '');
      }, FIRST_USER_LOADING_SCREEN_DELAY);

      history.push(`/account/setup/password/${passwordCode}`);
      // eslint-disable-next-line no-empty
    } catch { }
  };

  useEffect(() => {
    if (isOnSetPasswordPath) {
      correctSetPasswordCode = params.setPasswordCode!;
    } else {
      checkReservation();
    }
  }, []);

  const handleSubmit = async (values: IFirstUserAccountSetupPasswordForm, {
    setSubmitting,
    setErrors,
  }: FormikProps<IFirstUserAccountSetupPasswordForm>) => {
    try {
      productAnalytics.setUserContext(email);
      await users.setupUser({
        setPasswordCode: correctSetPasswordCode,
        password: values.password,
        userType,
      });

      setPasswordSetWithSuccess(true);

      setTimeout(() => {
        // TODO: should we delay that for success screen
        // and which should be url of destination (analytics currently)
        window.location.assign(config.get('ACCOUNT_SETUP_FIRST_USER_REDIRECT_PATH'));
      }, 3000);
    } catch (err: any) {
      if (err.name === 'TooManyRequestsError') {
        setErrors({ informationError: t('general.notifications.errors.rateLimit.text') });
      } else {
        setErrors({
          ...err.errors,
          informationError: t('general.notifications.errors.generic.text'),
        });
      }
    }

    setSubmitting(false);
  };

  if (isLoading && !isOnSetPasswordPath) {
    return <Loading t={t} />;
  }

  if (failed) {
    return (
      <NoMarketingViewContainer>
        <Errors
          viewType="firstUserSetupPassword"
          reason={failReason}
          history={history}
        />
      </NoMarketingViewContainer>
    );
  }

  return (
    <Marketing doNotShowLoader>
      <Stack vertical horizontalAlignment="middle">
        <Stack.Item>
          <Layout maxWidth="narrow">
            <Layout.Column>
              <div className={bindClasses('flex-wrapper')}>
                <div className={bindClasses('width-wrapper')}>
                  {(!isLoading || isOnSetPasswordPath) && !failReason && (
                    <Form
                      id="account-setup-first-user-setup-password-form"
                      initialValues={{
                        password: '',
                        passwordRepeated: '',
                      } as IFirstUserAccountSetupPasswordForm}
                      validationSchema={validation.createValidationSchema((validators: any) => ({
                        password: validators.users.password,
                        passwordRepeated: validators.users.passwordRepeated,
                      }))}
                      onSubmit={handleSubmit}
                      render={({
                        errors,
                        validateForm,
                        setFieldValue,
                        setFieldTouched,
                        values,
                      }: FormikProps<IFirstUserAccountSetupPasswordForm>) => (
                        <View>
                          <View.Body>
                            <Stack vertical>
                              <Stack.Item fill>
                                <Subtitle>
                                  {t('firstUser.setPasswordForm.header')}
                                </Subtitle>
                              </Stack.Item>
                              <Stack.Item fill previousSpacing="narrow">
                                <div style={{ textAlign: 'center' }}>
                                  <Text>
                                    {t('firstUser.setPasswordForm.description')}
                                  </Text>
                                </div>
                              </Stack.Item>
                              {errors.informationError && (
                                <Stack.Item fill>
                                  <Information type="error">
                                    {errors.informationError}
                                  </Information>
                                </Stack.Item>
                              )}
                              <Stack.Item fill>
                                <TextFieldControl
                                  name="password"
                                  type="password"
                                  label={t('firstUser.setPasswordForm.password.label')}
                                  info={t('firstUser.setPasswordForm.password.info')}
                                  required
                                  onBlur={() => validateForm(values)}
                                  onChange={(e: {
                                    target:
                                    { name: string, value: string }
                                  }) => {
                                    setFieldValue('password', e.target.value);
                                    setFieldTouched('password', true);
                                  }}
                                  autoFocus
                                />
                              </Stack.Item>
                              <Stack.Item fill>
                                <TextFieldControl
                                  name="passwordRepeated"
                                  type="password"
                                  label={t('firstUser.setPasswordForm.passwordRepeat.label')}
                                  required
                                  onBlur={() => validateForm(values)}
                                  onChange={(e: {
                                    target:
                                    { name: string, value: string }
                                  }) => {
                                    setFieldValue('passwordRepeated', e.target.value);
                                    setFieldTouched('passwordRepeated', true);
                                  }}
                                />
                              </Stack.Item>
                              <Stack.Item fill>
                                <Form.Submit pending={passwordSetWithSuccess} fullWidth text={t('firstUser.setPasswordForm.confirmBtn')} />
                              </Stack.Item>
                            </Stack>
                          </View.Body>
                        </View>
                      )}
                    />
                  )}
                </div>
              </div>
            </Layout.Column>
          </Layout>
        </Stack.Item>
      </Stack>
    </Marketing>
  );
};

export default connector(withInject<InjectedProps>({
  organization: 'OrganizationCrate.organization',
  users: 'UsersCrate.users',
  validation: 'FormCrate.validation',
  config: 'config',
  history: 'RouterCrate.history',
})(
  withTranslation(['astronauth'])(FirstUserSetupPassword),
));
