import { UserRolePass } from '@piwikpro/auth-crate/firewalls/UserRolePass';
import { UserAccessContext } from '../components/Secured/AccessContext';

export default () => (
  new class AccessManager {
    private firewallPasses: UserRolePass[];

    constructor(firewallPasses: UserRolePass[] = []) {
      this.firewallPasses = firewallPasses;
    }

    create(firewallPass: UserRolePass) {
      return new AccessManager(this.firewallPasses.concat([firewallPass]));
    }

    isGranted(extraContext: UserAccessContext) {
      if (this.firewallPasses.length === 0) return true;

      const passes = this.firewallPasses[Symbol.iterator]();

      const next = () => {
        const pass = passes.next();

        if (pass.done) {
          return true;
        }

        return pass.value.isAllowed(extraContext, next);
      };

      return passes.next().value.isAllowed(extraContext, next);
    }
  }()
);
