/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate } from '@piwikpro/platform';
import HttpCrate from '@piwikpro/http-crate';
import NotificationCrate from '@piwikpro/notification-crate';
import ConfirmationCrate from '@piwikpro/confirmation-crate';
import TranslationCrate from '@piwikpro/translation-crate';
import { Users } from './Users';

@Crate({
  name: 'UsersCrate',
  imports: [
    NotificationCrate,
    HttpCrate,
    ConfirmationCrate,
    TranslationCrate,
  ],
  services: [
    { name: 'users', provide: Users },
  ],
})
export class UsersCrate {}
