import { AppPermissionPass } from '@piwikpro/apps-crate/firewall/AppPermissionPass';
import React from 'react';

export interface UserAccessContext {
  role: 'OWNER' | 'USER'
  level?: string
}

export interface IAccessContext {
  userRole?: 'OWNER' | 'USER'
  accessManager?: ({
    isGranted: ({
      role,
      level,
    }: UserAccessContext) => boolean
    create: (
      level: string | AppPermissionPass
    ) => string | AppPermissionPass
  })
  userPermission?: string
}

export const AccessContext = React.createContext<IAccessContext>({});
