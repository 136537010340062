import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Tooltip,
  Icon,
  withTranslation,
  IconName,
  IconColor,
} from '@piwikpro/ui-components';

export interface ICopyIconProps {
  value: string
  t: (key: string) => string
}

const CopyIcon = ({ value, t }: ICopyIconProps) => {
  const [copied, setCopied] = useState(false);

  const icon: {
    name: IconName
    color: IconColor
  } = copied ? {
    name: 'checkmark-circle',
    color: 'green',
  } : {
    name: 'duplicate',
    color: 'gray',
  };

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Tooltip
      content={copied ? t('copied') : t('copy')}
      preventHideOnMouseDown
    >
      <div>
        <CopyToClipboard text={value}>
          {/*eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            role="button"
            tabIndex={0}
            onClick={!copied ? handleCopy : () => {}}
          >
            <Icon name={icon.name} color={icon.color} />
          </div>
        </CopyToClipboard>
      </div>
    </Tooltip>
  );
};

export default withTranslation('components')(CopyIcon);
