import React from 'react';
import { withTranslation, Stack } from '@piwikpro/ui-components';
import classNameBind from 'classnames/bind';
import styles from './NoMarketingViewContainer.module.css';

const bindClasses = classNameBind.bind(styles);

export interface NoMarketingViewContainerProps {
  children: React.ReactNode
}

const NoMarketingViewContainer = ({ children }: NoMarketingViewContainerProps) => (
  <div className={bindClasses('background')}>
    <div className={bindClasses('container')}>
      <Stack
        fullHeight
        vertical
        spacing="extraWide"
        verticalAlignment="center"
        horizontalAlignment="middle"
      >
        <Stack.Item>
          <div className={bindClasses('logo')}>
            <img alt="Logo" src="/statics/logo?v3" />
          </div>
        </Stack.Item>
        <Stack.Item>
          {children}
        </Stack.Item>
      </Stack>
    </div>
  </div>
);

export default withTranslation('components')(NoMarketingViewContainer);
