/* eslint-disable camelcase */
import { handleActions } from 'redux-actions';
import {
  confirmReservationInit,
  confirmReservationSucceeded,
  confirmReservationFailed,
} from '@piwikpro/organization-crate';

export interface IConfirmReservationProcessState {
  isLoading: boolean
  failed: boolean
  failReason: string
  passwordCode: string
}

const initialState: IConfirmReservationProcessState = {
  isLoading: true,
  failed: false,
  failReason: '',
  passwordCode: '',
};

export default handleActions<IConfirmReservationProcessState, any>({
  [confirmReservationInit.toString()]: () => ({
    ...initialState,
  }),
  [confirmReservationSucceeded.toString()]: (
    state,
    { payload }: {
      payload: {
        set_password_code: string
      }
    },
  ) => ({
    ...state,
    isLoading: false,
    passwordCode: payload.set_password_code,
  }),
  [confirmReservationFailed.toString()]: (state, { payload }: { payload: string }) => ({
    ...state,
    isLoading: false,
    failed: true,
    failReason: payload,
  }),
}, initialState);
