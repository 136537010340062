import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FormikProps } from 'formik';
import { TFunction } from 'react-i18next';
import { withInject } from '@piwikpro/platform';
import {
  Stack,
  Header,
  Information,
  Text,
  withTranslation,
} from '@piwikpro/ui-components';
import { FormValidator } from '@piwikpro/form-crate';
import VerificationCodeInput from '../../../../components/VerificationCodeInput';
import { AstronAuthService } from '../../../../services/AstronAuth';
import { TOTP_INPUT_NUMBER_OF_FIELDS } from '../../../../constants';
import { IAstronAuthState } from '../../../../reducers';

const mapStateToProps = (state: IAstronAuthState) => ({
  failed: state.authProcess.failed,
  failReason: state.authProcess.failReason,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

interface InjectedProps {
  astronAuthApi: AstronAuthService
  validation: FormValidator
}

interface Props extends ReduxProps, InjectedProps {
  t: TFunction
}

interface IFormValues {
  code: string
}

export const TotpInput = ({
  astronAuthApi,
  validation,
  failed,
  failReason,
  t,
}: Props) => {
  const incorrectCredentials = failReason === 'UnauthorizedRequestError';
  const rateLimitExceeded = failReason === 'TooManyRequestsError';
  const otherFailReason = !(incorrectCredentials || rateLimitExceeded);

  const handleSubmit = async (
    values: IFormValues,
    api: FormikProps<IFormValues>,
  ) => {
    try {
      await astronAuthApi.enterTotp({ code: values.code }, 'input-code');

      api.setSubmitting(false);
    } catch {
      api.resetForm();
      api.setSubmitting(false);
    }
  };

  return (
    <Stack vertical horizontalAlignment="middle">
      <Stack.Item>
        <Header type="small">
          {t('login.2fa.header')}
        </Header>
      </Stack.Item>
      <Stack.Item previousSpacing="narrow" fill>
        {/* TODO: verify subtitle component here to adjust left */}
        <div style={{ textAlign: 'center' }}>
          <Text>
            {t('login.2fa.description')}
          </Text>
        </div>
      </Stack.Item>
      {failed && (
        <Stack.Item fill>
          <Information type="error">
            {incorrectCredentials && t('login.2fa.errors.incorrectCode')}
            {rateLimitExceeded && t('general.notifications.errors.rateLimit.text')}
            {otherFailReason && t('login.passwordInput.errors.other')}
          </Information>
        </Stack.Item>
      )}
      <Stack.Item>
        <VerificationCodeInput
          id="totp-input-form"
          name="code"
          initialValue=""
          validationSchema={validation.createValidationSchema(
            () => ({
              code: validation.getYup().string().min(TOTP_INPUT_NUMBER_OF_FIELDS).required(),
            }),
          )}
          handleResetLoginProcess={async () => {
            try {
              await astronAuthApi.resetLoginProcess();
              await astronAuthApi.getAuthStep();
              // eslint-disable-next-line no-empty
            } catch {}
          }}
          onSubmit={handleSubmit}
          onBackupCodesLinkClick={() => astronAuthApi.showNextStep('totp/backup-code-input')}
          withBackupCodesLink
          loginProcessView
        />
      </Stack.Item>
    </Stack>
  );
};

export default connector(withInject<InjectedProps>({
  validation: 'FormCrate.validation',
  astronAuthApi: 'AstronAuthCrate.astronAuth',
})(withTranslation(['astronauth'])(TotpInput)));
