/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { TFunction } from 'react-i18next';
import {
  Stack,
  Link,
  Spinner,
  Divider,
  Caption,
  bindControlToForm,
  autoSaved,
  withTranslation,
} from '@piwikpro/ui-components';
import classNameBind from 'classnames/bind';
import styles from './VerificationCodeInput.module.css';
import { TOTP_INPUT_NUMBER_OF_FIELDS } from '../../constants';

const bindClasses = classNameBind.bind(styles);

export interface IVerificationCodeInputProps {
  name: string
  value: string
  disabled: boolean
  onBackupCodesLinkClick?: () => void
  withBackupCodesLink?: boolean
  onChange: ({
    target: { name, value },
  }: {
    target: { name: string, value: string }
  }) => void
  handleResetLoginProcess: () => void
  loginProcessView: boolean
  t: TFunction
}

export const VerificationCodeInput = ({
  name,
  onChange,
  value,
  disabled,
  onBackupCodesLinkClick,
  withBackupCodesLink,
  loginProcessView,
  handleResetLoginProcess,
  t,
}: IVerificationCodeInputProps) => {
  const [currentField, setCurrentField] = useState(0);
  const inputsRefs = useRef<HTMLElement[]>([]);

  const handleClick = () => {
    if (inputsRefs.current[currentField]) {
      inputsRefs.current[currentField].focus();
    } else {
      inputsRefs.current[currentField - 1].focus();
    }
  };

  const handleChange = (newValue: string) => {
    onChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      const key = Number(e.keyCode || e.charCode);
      if (key === 69) { // disallow enter number in "e" notation
        e.preventDefault();
        return;
      }
      if (key === 8 || key === 46) { // handle backspace
        if (currentField !== 0) {
          handleChange(value.substring(0, value.length - 1));
        }
      }
    },
    [currentField],
  );

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData('text/plain').trim().replace(/\D+/g, '');
    const calculatedPosition = currentField + pastedValue.length;

    setTimeout(() => {
      setCurrentField(
        calculatedPosition > TOTP_INPUT_NUMBER_OF_FIELDS
          ? TOTP_INPUT_NUMBER_OF_FIELDS : calculatedPosition,
      );
    }, 0);
  };

  useEffect(() => {
    inputsRefs.current[currentField]?.focus();
  }, [currentField]);

  useEffect(() => {
    setCurrentField(value.length);
  }, [value]);

  return (
    <div className={bindClasses('wrapper')}>
      <Stack vertical horizontalAlignment="middle">
        <Stack.Item>
          <div
            role="button"
            tabIndex={-1}
            className={bindClasses('inputs')}
            onClick={handleClick}
          >
            {Array.from(Array(TOTP_INPUT_NUMBER_OF_FIELDS).keys()).map(id => (
              <input
                ref={(el) => {
                  inputsRefs.current[id] = el!;
                }}
                key={id}
                onKeyDown={handleKeyDown as any}
                min={0}
                max={9}
                maxLength={1}
                name={name}
                value={value[id] || ''}
                onPaste={handlePaste}
                onChange={(e) => {
                  if (currentField < TOTP_INPUT_NUMBER_OF_FIELDS) {
                    if (e.target.value !== '') {
                      setCurrentField(currentField + 1);
                      handleChange(value + e.target.value);
                    }
                  }
                }}
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                autoComplete="one-time-code"
                className={bindClasses(
                  { filled: value[id], disabled },
                  'single-input',
                )}
                disabled={disabled}
              />
            ))}
            {disabled && (
              <div className={bindClasses('spinner')}>
                <Spinner className="small" />
              </div>
            )}
          </div>
        </Stack.Item>
        {withBackupCodesLink && (
          <Stack.Item>
            <div
              className={bindClasses('link', { disabled })}
            >
              <Link
                size="small"
                onClick={onBackupCodesLinkClick}
              >
                {t('login.2fa.backupCode.link')}
              </Link>
            </div>
          </Stack.Item>
        )}
        {loginProcessView
          ? (
            <>
              <Stack.Item fill>
                <Divider noMargin />
              </Stack.Item>
              <Stack.Item fill>
                <div style={{ textAlign: 'center' }}>
                  <Caption subdued>
                    {t('login.2faBackupCodesInput.useAnotherAccountLink.part1')}
                    <span className={bindClasses({ disabled })}>
                      <Link size="small" onClick={handleResetLoginProcess}>{t('login.2faBackupCodesInput.useAnotherAccountLink.part2')}</Link>
                    </span>
                    {t('login.2faBackupCodesInput.useAnotherAccountLink.part3')}
                  </Caption>
                </div>
              </Stack.Item>
            </>
          ) : (
            <>
              <Stack.Item>
                <div
                  className={bindClasses('link', { disabled })}
                >
                  <Link
                    size="small"
                    onClick={handleResetLoginProcess}
                  >
                    {t('login.2fa.backupCode.useAnotherAccount')}
                  </Link>
                </div>
              </Stack.Item>
            </>
          )}
      </Stack>
    </div>
  );
};

export default withTranslation(['astronauth'])(autoSaved({ saveOnChange: true })(
  bindControlToForm()(VerificationCodeInput),
));
