import React from 'react';
import { TFunction } from 'react-i18next';
import { withInject } from '@piwikpro/platform';
import {
  Stack,
  Header,
  Button,
  withTranslation,
} from '@piwikpro/ui-components';
import { AstronAuthService } from '../../../../services/AstronAuth';

interface InjectedProps {
  astronAuthApi: AstronAuthService
}

interface Props extends InjectedProps {
  redirectPath: string
  t: TFunction
}

export const SamlLogin = ({
  astronAuthApi,
  redirectPath,
  t,
}: Props) => (
  <Stack vertical horizontalAlignment="middle">
    <Stack.Item>
      <Header type="small">
        {t('login.ssoLogin.header')}
      </Header>
    </Stack.Item>
    <Stack.Item>
      <Button
        text={t('login.ssoLogin.loginBtn')}
        appearance="primary"
        onClick={() => astronAuthApi.loginWithSaml({ redirectPath })}
      />
    </Stack.Item>
  </Stack>
);

export default withInject<InjectedProps>({
  astronAuthApi: 'AstronAuthCrate.astronAuth',
})(withTranslation(['astronauth'])(SamlLogin));
