import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNameBind from 'classnames/bind';
import { withInject } from '@piwikpro/platform';
import { Spinner, withTranslation } from '@piwikpro/ui-components';
import PasswordInput from './Steps/PasswordInput';
import TotpInput from './Steps/TotpInput';
import TotpConfigure from './Steps/TotpConfigure';
import TotpBackupCodes from './Steps/TotpBackupCodes';
import TotpBackupCodesInput from './Steps/TotpBackupCodeInput';
import SamlLogin from './Steps/SamlLogin';
import styles from './Login.module.css';
import { AstronAuthService } from '../../services/AstronAuth';
import { IAstronAuthState } from '../../reducers';
import { useQuery } from '../../hooks/useQuery';

const bindClasses = classNameBind.bind(styles);

const mapStateToProps = (state: IAstronAuthState) => ({
  authStep: state.authProcess.step,
  isLoading: state.authProcess.isLoading,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

interface InjectedProps {
  astronAuthApi: AstronAuthService
}

interface Props extends ReduxProps, InjectedProps {}

const Loader = () => (
  <div className={bindClasses('initial-loader')}>
    <Spinner className="large" />
  </div>
);

export const Login = ({
  astronAuthApi,
  authStep,
  isLoading,
}: Props) => {
  const relayState = useQuery('RelayState');
  const redirectPath = astronAuthApi.getSafeAbsoluteRedirectPath(relayState);
  // SSO doesn't support absolute paths
  const relativeRedirectPath = astronAuthApi.getSafeRelativeRedirectPath(relayState);
  const shouldDisplayLoader = isLoading || astronAuthApi.isAuthenticated();

  useEffect(() => {
    (async () => {
      await astronAuthApi.getAuthStep().catch(() => {});
    })();
  }, []);

  if (astronAuthApi.isAuthenticated() && authStep !== 'totp/backup-codes') {
    window.location.replace(redirectPath);
  }

  const renderAuthStep = () => {
    switch (authStep) {
      case 'totp/configure':
        return <TotpConfigure />;
      case 'totp/input-code':
        return <TotpInput />;
      case 'totp/backup-codes':
        return <TotpBackupCodes redirectPath={redirectPath} />;
      case 'totp/backup-code-input':
        return <TotpBackupCodesInput />;
      case 'saml/login':
        return <SamlLogin redirectPath={relativeRedirectPath} />;
      default:
      case 'password/input-credentials':
        return <PasswordInput />;
    }
  };

  return shouldDisplayLoader ? Loader() : renderAuthStep();
};

export default connector(withInject<InjectedProps>({
  astronAuthApi: 'AstronAuthCrate.astronAuth',
})(withTranslation(['administration'])(Login)));
