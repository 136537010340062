// eslint-disable-next-line max-len
export const delayAction = <T extends Function>(callbackFnc: T, delay: number = 100): Promise<T> => (
  new Promise(resolve => setTimeout(() => resolve(callbackFnc()), delay))
);

export const getSecretFromTotpUri = (totpUri: string) => {
  const match = totpUri.match(/secret=(.*?)&/i);
  if (match) {
    return match[1];
  }

  return '';
};
