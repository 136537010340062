import type { Store } from 'redux';
import {
  Service,
  inject,
} from '@piwikpro/platform';
import { ProductAnalytics } from '@piwikpro/product-analytics';
import {
  NotifyAboutCreation, NotifyAboutUpdate, NotifyOnFail, CustomNotification,
} from '@piwikpro/notification-crate';
import { Confirmation } from '@piwikpro/confirmation-crate';
import { HttpClient } from '@piwikpro/http-crate';
import { AuthSettingsResponse, SignatureValidations } from './interfaces';
import {
  getAuthSettingsForOrganizationInitialized,
  getAuthSettingsForOrganizationSucceeded,
  getAuthSettingsForOrganizationFailed,
  updateAuthSettingsForOrganizationSucceeded,
  updateAuthSettingsForOrganizationFailed,
  getAuthSettingsForUserInitialized,
  getAuthSettingsForUserSucceeded,
  getAuthSettingsForUserFailed,
  get2faSetupForUserInitialized,
  get2faSetupForUserSucceeded,
  get2faSetupForUserFailed,
  set2faSetupForUserSucceeded,
  set2faSetupForUserFailed,
  delete2faSetupForUserSucceeded,
  delete2faSetupForUserFailed,
  reset2faForSpecificUserInitialized,
  reset2faForSpecificUserSucceeded,
  reset2faForSpecificUserFailed,
  passwordChangeInitialized,
  passwordChangeSucceeded,
  passwordChangeFailed,
  getSamlMetadataInitialized,
  getSamlMetadataSucceeded,
  getSamlMetadataFailed,
  testSamlSettingsInitialized,
  testSamlSettingsSucceeded,
  testSamlSettingsFailed,
  deleteSamlSettingsInitialized,
  deleteSamlSettingsSucceeded,
  deleteSamlSettingsFailed,
} from './reducers/auth';
import { SamlDeleteModal } from './components/SamlDeleteModal/SamlDeleteModal';
import { SamlTurnOffModal } from './components/SamlTurnOffModal/SamlTurnOffModal';

@Service()
export class AuthSettings {
  constructor(
    @inject('store') private readonly store: Store,
    @inject('HttpCrate.httpClient') private readonly httpClient: HttpClient,
    @inject('NotificationCrate.interceptors.NotifyOnFail') private readonly notifyOnFailInterceptor: NotifyOnFail,
    @inject('NotificationCrate.interceptors.NotifyAboutUpdate') private readonly notifyAboutUpdate: NotifyAboutUpdate,
    @inject('NotificationCrate.interceptors.NotifyAboutCreation') private readonly notifyAboutCreation: NotifyAboutCreation,
    @inject('NotificationCrate.interceptors.CustomNotification') private readonly customNotification: CustomNotification,
    @inject('ConfirmationCrate.confirmation') private readonly confirmation: Confirmation,
    @inject('productAnalytics') private readonly productAnalytics: ProductAnalytics,
    @inject('config') private readonly config: any,
  ) { }

  async getSettingsForOrganization(): Promise<AuthSettingsResponse | Error> {
    try {
      this.store.dispatch(getAuthSettingsForOrganizationInitialized());
      const resp: AuthSettingsResponse = await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/organization`,
        method: 'GET',
      })
        .intercept(this.notifyOnFailInterceptor)
        .json()
        .send();

      this.store.dispatch(
        getAuthSettingsForOrganizationSucceeded(resp),
      );

      return resp;
    } catch (err) {
      this.store.dispatch(getAuthSettingsForOrganizationFailed());

      throw err;
    }
  }

  async set2faSettingsForOrganization(
    enforced: boolean,
  ): Promise<any> {
    const getConfirmationMessage = (
      enforcing: boolean,
    ) => (enforcing ? {
      messages: [
        'accountSecurity.twoFactorAuth.turn-on-confirmation.messageHeader',
        'accountSecurity.twoFactorAuth.turn-on-confirmation.message',
        'accountSecurity.twoFactorAuth.turn-on-confirmation.question',
      ],
      title: 'administration:accountSecurity.twoFactorAuth.turn-on-confirmation.title',
      applyBtnText: 'administration:accountSecurity.twoFactorAuth.turn-on-confirmation.applyButton',
      applyBtnAppearance: 'primary',
    } : {
      messages: [
        'accountSecurity.twoFactorAuth.turn-off-confirmation.messageHeader',
        'accountSecurity.twoFactorAuth.turn-off-confirmation.message',
        'accountSecurity.twoFactorAuth.turn-off-confirmation.question',
      ],
      title: 'administration:accountSecurity.twoFactorAuth.turn-off-confirmation.title',
      applyBtnText: 'administration:accountSecurity.twoFactorAuth.turn-off-confirmation.applyButton',
      applyBtnAppearance: 'primary',
    });

    return this.confirmation.confirm({
      ...getConfirmationMessage(enforced),
    })
      .then(async () => {
        await this.httpClient.request({
          endpoint: `${this.config.get('AUTH_URL')}/settings/organization/2fa`,
          method: 'PATCH',
          body: {
            enforced,
          },
        }).intercept(this.notifyAboutUpdate).json().send();

        this.store.dispatch(updateAuthSettingsForOrganizationSucceeded());

        this.productAnalytics.push({ type: `@platform/command/auth/2faSettingsForOrganization/${enforced ? 'turnOn' : 'turnOff'}/success` });
      }).catch((err: any) => {
        this.store.dispatch(updateAuthSettingsForOrganizationFailed());

        this.productAnalytics.push({ type: `@platform/command/auth/2faSettingsForOrganization/${enforced ? 'turnOn' : 'turnOff'}/failed/${err.name}` });

        return Promise.reject(err);
      });
  }

  async getSettingsForUser(userId: string) {
    this.store.dispatch(getAuthSettingsForUserInitialized());
    try {
      const resp = await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/user/${userId}`,
        method: 'GET',
      }).json().send<any>();

      this.store.dispatch(getAuthSettingsForUserSucceeded(resp));

      return resp;
    } catch (err: any) {
      this.store.dispatch(getAuthSettingsForUserFailed(err.name));

      throw err;
    }
  }

  reinitializeSetup2faForCurrentUser(): void {
    this.store.dispatch(get2faSetupForUserInitialized());
  }

  async setup2faForCurrentUser({ code }: { code?: string } = {}) {
    this.store.dispatch(get2faSetupForUserInitialized());

    try {
      const resp = await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/user/me/2fa/totp/setup`,
        method: 'POST',
        body: {
          code,
        },
      }).json().send<any>();

      this.store.dispatch(get2faSetupForUserSucceeded(resp));

      return resp;
    } catch (err: any) {
      this.store.dispatch(get2faSetupForUserFailed(err.name));

      throw err;
    }
  }

  async enter2faCodeForCurrentUser(code: string, overwrite: boolean) {
    try {
      const resp = await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/user/me/2fa/totp/configure`,
        method: overwrite ? 'PUT' : 'POST',
        body: {
          code,
        },
      }).json().send<any>();

      this.store.dispatch(set2faSetupForUserSucceeded(resp));

      this.productAnalytics.push({ type: `@platform/command/auth/2faSetupForUser/${overwrite ? 'reset' : 'turnOn'}/success` });

      return resp;
    } catch (err: any) {
      this.store.dispatch(set2faSetupForUserFailed(err.name));

      this.productAnalytics.push({ type: `@platform/command/auth/2faSetupForUser/${overwrite ? 'reset' : 'turnOn'}/failed/${err.name}` });

      throw err;
    }
  }

  async delete2faForCurrentUser({ code }: { code: string }): Promise<undefined | Error> {
    try {
      await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/user/me/2fa/totp`,
        method: 'DELETE',
        body: {
          code,
        },
      }).intercept(() => this.customNotification.withConfig({
        pending: {
          text: 'profile:security.2fa.notifications.turned-off.pending',
          type: 'pending',
        },
        success: {
          text: 'profile:security.2fa.notifications.turned-off.success',
          type: 'success',
        },
      })).json().send<any>();

      this.store.dispatch(delete2faSetupForUserSucceeded()); // also used for product analytics

      return;
    } catch (err: any) {
      this.store.dispatch(delete2faSetupForUserFailed(err.name)); // also used for product analytics

      throw err;
    }
  }

  async reset2faForUser(
    { id, successMessage }: { id: string, successMessage: string },
  ): Promise<void | Error> {
    return this.confirmation.confirm({
      messages: [
        'administration:users.security.reset2fa.modal.message',
        'administration:users.security.reset2fa.modal.question',
      ],
      title: 'administration:users.security.reset2fa.modal.title',
      applyBtnAppearance: 'primary',
      applyBtnText: 'administration:users.security.reset2fa.modal.resetButton',
    }).then(
      async () => {
        this.store.dispatch(reset2faForSpecificUserInitialized());
        await this.httpClient.request({
          endpoint: `${this.config.get('AUTH_URL')}/settings/user/${id}/2fa/totp`,
          method: 'DELETE',
        })
          .intercept(() => this.customNotification.withConfig({
            success: {
              text: successMessage,
              type: 'success',
            },
          }))
          .json()
          .send();
        this.store.dispatch(reset2faForSpecificUserSucceeded()); // also used for product analytics
      },
    ).catch(
      (err) => {
        this.store.dispatch(reset2faForSpecificUserFailed());

        this.productAnalytics.push({ type: `${reset2faForSpecificUserFailed().type}/${err.name}` });

        return Promise.reject(err);
      },
    );
  }

  async changePassword({ currentPassword, newPassword }: {
    currentPassword: string
    newPassword: string
  }): Promise<Error | undefined> {
    this.store.dispatch(passwordChangeInitialized());

    try {
      await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/user/me/password`,
        method: 'POST',
        body: {
          current_password: currentPassword,
          new_password: newPassword,
        },
      })
        .intercept(this.notifyAboutUpdate)
        .json()
        .send<any>();

      //action used for product analytics
      this.store.dispatch(passwordChangeSucceeded());
      return;
    } catch (err: any) {
      this.store.dispatch(passwordChangeFailed());
      this.productAnalytics.push({ type: `@platform/command/me/changePassword/${err.name}` });
      throw err;
    }
  }

  async getSamlMetadata() {
    this.store.dispatch(getSamlMetadataInitialized());

    try {
      const resp = await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/saml/metadata`,
        method: 'GET',
      }).text().send<any>();

      this.store.dispatch(getSamlMetadataSucceeded(resp));

      return resp;
    } catch (err: any) {
      this.store.dispatch(getSamlMetadataFailed());

      throw err;
    }
  }

  async setSamlAuthentication({ enable }: { enable: boolean }) {
    return this.confirmation.confirm({
      messages: [
        'administration:accountSecurity.saml-turn-on.modal.message',
        'administration:accountSecurity.saml-turn-on.modal.question',
      ],
      type: enable ? 'simple' : 'customBody',
      title: enable ? 'administration:accountSecurity.saml-turn-on.modal.title' : 'administration:accountSecurity.saml-turn-off.modal.title',
      applyBtnAppearance: 'primary',
      applyBtnText: enable ? 'administration:accountSecurity.saml-turn-on.modal.apply-btn' : 'administration:accountSecurity.saml-turn-off.modal.apply-btn',
    }, () => SamlTurnOffModal()).then(
      async () => {
        await this.httpClient.request({
          endpoint: `${this.config.get('AUTH_URL')}/settings/organization/saml`,
          method: 'PATCH',
          body: {
            enabled: enable,
          },
        })
          .intercept(() => this.customNotification.withConfig({
            success: {
              text: enable ? 'administration:accountSecurity.saml-turn-on.notification.success' : 'administration:accountSecurity.saml-turn-off.notification.success',
              type: 'success',
            },
          }))
          .json()
          .send();

        this.productAnalytics.push({ type: `@platform/command/auth/samlSettings/${enable ? 'turnOn' : 'turnOff'}/success` });
      },
    ).catch((err) => {
      this.productAnalytics.push({ type: `@platform/command/auth/samlSettings/${enable ? 'turnOn' : 'turnOff'}/failed` });

      throw err;
    });
  }

  async setSessionLength({ length, successMessage }: { length: number, successMessage: string }) {
    try {
      await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/organization/session`,
        method: 'PUT',
        body: {
          inactivity_timeout: length,
        },
      })
        .intercept(() => this.customNotification.withConfig({
          success: {
            text: successMessage,
            type: 'success',
          },
        }))
        .json()
        .send();
      this.productAnalytics.push({ type: `@platform/command/auth/setSessionLength/${length}/success` });
    } catch (err) {
      this.productAnalytics.push({ type: `@platform/command/auth/setSessionLength/${length}/failed` });

      throw err;
    }
  }

  async setSupportUserAccess({ enabled }: { enabled: boolean }) {
    try {
      await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/organization/support`,
        method: 'PUT',
        body: {
          enabled,
        },
      })
        .intercept(this.notifyAboutCreation)
        .json()
        .send();
      this.productAnalytics.push({ type: `@platform/command/auth/setSupportUserAccess/${enabled ? 'enabled' : 'disabled'}/success` });
    } catch (err) {
      this.productAnalytics.push({ type: `@platform/command/auth/setSupportUserAccess/${enabled ? 'enabled' : 'disabled'}/failed` });

      throw err;
    }
  }

  async setAutoProvisioningOfUsers({ enabled }: { enabled: boolean }) {
    try {
      await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/organization/auto-provisioning`,
        method: 'PUT',
        body: {
          enabled,
        },
      })
        .intercept(this.notifyAboutUpdate)
        .json()
        .send();

      this.productAnalytics.push({ type: `@platform/command/auth/autoProvisioningForOrganization/${enabled ? 'turnOn' : 'turnOff'}/success` });
    } catch (err: any) {
      this.productAnalytics.push({ type: `@platform/command/auth/autoProvisioningForOrganization/${enabled ? 'turnOn' : 'turnOff'}/failed/${err?.name}` });

      throw err;
    }
  }

  async testSamlAuthentication(
    {
      signOnUrl,
      entityId,
      publicCertificate,
      signatureValidation,
    }: {
      signOnUrl: string
      entityId: string
      publicCertificate: string
      signatureValidation: SignatureValidations
    },
  ) {
    this.store.dispatch(testSamlSettingsInitialized());

    try {
      const resp = await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/settings/organization/saml`,
        method: 'PUT',
        body: {
          identity_provider: {
            sign_on_url: signOnUrl,
            entity_id: entityId,
            x509_certificate: publicCertificate,
          },
          service_provider: {
            signature_validation: signatureValidation,
          },
        },
      })
        .intercept(this.notifyAboutCreation)
        .json()
        .send();

      this.store.dispatch(testSamlSettingsSucceeded()); // also used for product analytics
      return resp;
    } catch (err: any) {
      this.store.dispatch(testSamlSettingsFailed()); // also used for product analytics

      throw err;
    }
  }

  async deleteSamlAuthentication() {
    this.store.dispatch(deleteSamlSettingsInitialized());
    return this.confirmation.confirm({
      type: 'customBody',
      title: 'administration:accountSecurity.saml-delete.modal.title',
      applyBtnAppearance: 'destructive',
      applyBtnText: 'administration:accountSecurity.saml-delete.modal.apply-btn',
    }, () => SamlDeleteModal()).then(
      async () => {
        await this.httpClient.request({
          endpoint: `${this.config.get('AUTH_URL')}/settings/organization/saml`,
          method: 'DELETE',
        })
          .intercept(() => this.customNotification.withConfig({
            success: {
              text: 'administration:accountSecurity.saml-delete.notification.success',
              type: 'success',
            },
          }))
          .json()
          .send();
        this.store.dispatch(deleteSamlSettingsSucceeded()); // also used for product analytics
      },
    ).catch((err) => {
      this.store.dispatch(deleteSamlSettingsFailed()); // also used for product analytics
      throw err;
    });
  }
}
