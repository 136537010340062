/* eslint-disable camelcase */
import type { Store } from 'redux';
import { Service, inject } from '@piwikpro/platform';
import { HttpClient } from '@piwikpro/http-crate';
import { ProductAnalytics } from '@piwikpro/product-analytics/ProductAnalytics';
import {
  authProcessStepFetchInitialized,
  authProcessStepFetchSucceeded,
  authProcessStepFetchFailed,
  checkResetPasswordCodeFailed,
  checkResetPasswordCodeInitialized,
  checkResetPasswordCodeSucceeded,
  AuthStepsType,
  IAstronAuthState,
  IAuthProcessResponse,
} from '../reducers';

@Service()
export class AstronAuthService {
  constructor(
    @inject('store') private readonly store: Store<IAstronAuthState>,
    @inject('location') private readonly location: Location,
    @inject('HttpCrate.httpClient') private httpClient: HttpClient,
    @inject('productAnalytics') private productAnalytics: ProductAnalytics,
  ) {}

  async getAuthStep() {
    this.store.dispatch(authProcessStepFetchInitialized());
    try {
      const resp: IAuthProcessResponse = await this.httpClient.request({
        endpoint: '/api/auth/v1/process',
        method: 'GET',
        withoutRedirect: true,
      }).json().send();

      this.store.dispatch(authProcessStepFetchSucceeded(resp));

      return resp;
    } catch (err: any) {
      this.store.dispatch(authProcessStepFetchFailed(err.name));

      throw err;
    }
  }

  async login(values: { email: string, password: string }) {
    try {
      this.productAnalytics.setUserContext(values.email);
      const resp: IAuthProcessResponse = await this.httpClient.request({
        endpoint: '/api/auth/v1/process/password/input-credentials',
        method: 'POST',
        body: {
          ...values,
        },
        withoutRedirect: true,
      }).json().send();

      this.store.dispatch(authProcessStepFetchSucceeded(resp));

      this.productAnalytics.push({ type: '@astronauth/command/inputCredentials/success' });
      return resp;
    } catch (err: any) {
      this.store.dispatch(authProcessStepFetchFailed(err.name));

      this.productAnalytics.push({ type: `@astronauth/command/inputCredentials/${err.name}` });

      throw err;
    }
  }

  async resetLoginProcess() {
    try {
      await this.httpClient.request({
        endpoint: '/api/auth/v1/process',
        method: 'DELETE',
        withoutRedirect: false,
      }).json().send();

      return;
    } catch (err: any) {
      throw err;
    }
  }

  getSafeAbsoluteRedirectPath(url?: string | null) {
    const ownOrigin = document.location.origin;

    try {
      if (!url || !url.startsWith('/')) {
        throw Error();
      }

      // call via URL to initially test that relative path is valid
      // (otherwise exception will be thrown)
      const parsedUrl = new URL(url, ownOrigin);
      const { search, pathname, hash } = parsedUrl;
      const hasHashPath = hash.length > 0;

      if (hasHashPath) {
        return `${ownOrigin}${pathname}${search}${hash}`;
      }

      return `${ownOrigin}${pathname}${search}`;
    } catch {
      return ownOrigin;
    }
  }

  getSafeRelativeRedirectPath(url: string) {
    const ownOrigin = document.location.origin;
    const absolutePath = this.getSafeAbsoluteRedirectPath(url);

    return absolutePath.replace(ownOrigin, '');
  }

  loginWithSaml({ redirectPath }: { redirectPath: string } = { redirectPath: '' }) {
    this.location.replace(`/api/auth/v1/saml/sso?RelayState=${redirectPath}`);
  }

  async enterTotp(values: { code: string }, type = 'input') {
    try {
      const resp: IAuthProcessResponse = await this.httpClient.request({
        endpoint: `/api/auth/v1/process/totp/${type}`,
        method: 'POST',
        body: {
          ...values,
        },
        withoutRedirect: true,
      }).json().send();

      // to avoid overwrite data after configure and display backup code screen
      // but it won't work due to authorized flag won't set after all
      if (type !== 'configure') {
        this.productAnalytics.push({ type: '@astronauth/command/enterTotp/success' });
        this.store.dispatch(authProcessStepFetchSucceeded(resp));
      }

      this.productAnalytics.push({ type: '@astronauth/query/configureTotp/success' });

      return resp;
    } catch (err: any) {
      if (type === 'configure') {
        this.productAnalytics.push({ type: `@astronauth/query/configureTotp/failed/${err.name}` });
      }

      this.store.dispatch(authProcessStepFetchFailed(err.name));
      this.productAnalytics.push({ type: `@astronauth/command/enterTotp/${err.name}` });

      throw err;
    }
  }

  async resetPasswordRequest({ email }: { email: string }) {
    try {
      this.productAnalytics.setUserContext(email);
      await this.httpClient.request({
        endpoint: '/api/auth/v1/reset-password',
        method: 'POST',
        body: {
          email,
        },
        withoutRedirect: true,
      }).json().send();

      this.productAnalytics.push({ type: '@astronauth/command/resetPasswordRequest/success' });
    } catch (err: any) {
      this.productAnalytics.push({ type: `@astronauth/command/resetPasswordRequest/${err.name}` });
      throw err;
    }
  }

  async checkResetPasswordCode({ code }: { code: string }) {
    this.store.dispatch(checkResetPasswordCodeInitialized());

    try {
      const resp: {
        language: string
      } = await this.httpClient.request({
        endpoint: `/api/auth/v1/reset-password/code/${code}`,
        method: 'GET',
        withoutRedirect: true,
      }).json().send<any>();

      this.store.dispatch(checkResetPasswordCodeSucceeded(code));

      return resp;
    } catch (err: any) {
      this.store.dispatch(checkResetPasswordCodeFailed(err));

      throw err;
    }
  }

  async resetPassword(
    { code, password }: { code: string, password: string },
  ) {
    try {
      await this.httpClient.request({
        endpoint: `/api/auth/v1/reset-password/code/${code}`,
        method: 'POST',
        body: {
          password,
        },
        withoutRedirect: true,
      }).json().send();
      this.productAnalytics.push({ type: '@astronauth/command/resetPassword/success' });
    } catch (err: any) {
      this.productAnalytics.push({ type: `@astronauth/command/resetPassword/${err.name}` });
      throw err;
    }
  }

  showNextStep(step: AuthStepsType) {
    this.store.dispatch(authProcessStepFetchSucceeded({
      ...this.store.getState().authProcess,
      next: step,
    }));
  }

  isAuthenticated() {
    return this.store.getState().authProcess.authenticated;
  }
}
