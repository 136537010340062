/* eslint-disable no-param-reassign */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TFunction } from 'react-i18next';
import { withInject } from '@piwikpro/platform';
import { Notifications } from '@piwikpro/notification-crate';
import {
  Icon,
  Stack,
  Header,
  Text,
  Button,
  CodeSnippet,
  Divider,
  withTranslation,
} from '@piwikpro/ui-components';
import { IAstronAuthState } from '../../../../reducers';

const mapStateToProps = (state: IAstronAuthState) => ({
  stepData: state.authProcess.data,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

interface InjectedProps {
  notifications: Notifications
}

interface Props extends ReduxProps, InjectedProps {
  redirectPath: string
  t: TFunction
}

export const TotpBackupCodes = ({
  stepData,
  notifications,
  redirectPath,
  t,
}: Props) => {
  // eslint-disable-next-line no-return-assign
  const backupCodes = stepData.backup_codes!.reduce((prev, curr, currIndex) => (
    (currIndex === stepData.backup_codes!.length - 1)
      ? prev += `${curr}`
      : prev += `${curr}\n`
  ), '');

  const handleCopyToClipboard = (result: boolean) => {
    if (result) {
      notifications.push({
        text: 'components:notifications.copied.success',
        type: 'success',
      });
    } else {
      notifications.push({
        text: 'components:notifications.copied.error',
        type: 'error',
      });
    }
  };

  return (
    <>
      <Stack vertical horizontalAlignment="middle">
        <Stack.Item>
          <Icon size="large" color="green" name="checkmark-circle" />
        </Stack.Item>
        <Stack.Item>
          <Header type="small">
            {t('login.2faBackupCodes.header')}
          </Header>
        </Stack.Item>
        <Stack.Item previousSpacing="narrow">
          <Header type="small">
            {t('login.2faBackupCodes.subheader')}
          </Header>
        </Stack.Item>
      </Stack>
      <Stack vertical horizontalAlignment="middle">
        <Stack.Item fill previousSpacing="extraWide">
          <Divider />
        </Stack.Item>
        <Stack.Item>
          <Text>
            {t('login.2faBackupCodes.description')}
          </Text>
        </Stack.Item>
        <Stack.Item fill>
          <CodeSnippet
            code={backupCodes}
            onCopy={handleCopyToClipboard}
            mode="no-highlight"
          />
        </Stack.Item>
        <Stack.Item fill>
          <Button
            appearance="primary"
            text={t('login.2faBackupCodes.doneBtn')}
            onClick={() => window.location.assign(redirectPath)}
            fullWidth
          />
        </Stack.Item>
      </Stack>
    </>
  );
};

export default connector(withInject<InjectedProps>({
  notifications: 'NotificationCrate.notifications',
})(withTranslation(['astronauth'])(TotpBackupCodes)));
